// actions.js
// Action types for home carousel fetching request, success, and failure
export const FETCH_HOMECAROUSEL_REQUEST = "FETCH_HOMECAROUSEL_REQUEST";
export const FETCH_HOMECAROUSEL_SUCCESS = "FETCH_HOMECAROUSEL_SUCCESS";
export const FETCH_HOMECAROUSEL_FAILURE = "FETCH_HOMECAROUSEL_FAILURE";

// Action creator for home carousel fetching request
export const fecthHomeCarousel = (payload) => ({
  type: FETCH_HOMECAROUSEL_REQUEST,
  payload,
});

// Action creator for home carousel fetching success
export const fecthHomeCarouselSuccess = (homeCarouselData) => ({
  type: FETCH_HOMECAROUSEL_SUCCESS,
  payload: homeCarouselData,
});

// Action creator for home carousel fetching failure
export const fecthHomeCarouselFailure = (error) => ({
  type: FETCH_HOMECAROUSEL_FAILURE,
  payload: error,
});
