// Import the required functions from the Firebase SDK
import { initializeApp } from "firebase/app"; // Imports the initializeApp function from the firebase/app module
import { getMessaging, getToken } from "firebase/messaging"; // Imports the getMessaging and getToken functions from the firebase/messaging module

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCsHFId_B9mLGsBxjiUPpafY-MfyLcoCnw", // Your Firebase API key
    authDomain: "shalom-tv-cd495.firebaseapp.com", // Your Firebase auth domain
    databaseURL: "https://shalom-tv-cd495.firebaseio.com", // Your Firebase Realtime Database URL
    projectId: "shalom-tv-cd495", // Your Firebase project ID
    storageBucket: "shalom-tv-cd495.appspot.com", // Your Firebase storage bucket
    messagingSenderId: "871376473990", // Your Firebase messaging sender ID
    appId: "1:871376473990:web:ce2a85b2f3aa0b1178a607", // Your Firebase app ID
    measurementId: "G-2VRZD8BJTZ" // Your Firebase measurement ID (optional)
};

// Initialize the Firebase app with the provided configuration
const app = initializeApp(firebaseConfig);

// Export the messaging instance for later use
export const messaging = getMessaging(app);

// Define an async function to generate a Firebase Cloud Messaging token
// export const generateToken = async () => {
//     // Request notification permission from the user
//     const permission = await Notification.requestPermission();

//     // If permission is granted
//     if (permission === "granted") {
//         console.log('permission::', permission) // Log the permission status

//         // Get the FCM token using the messaging instance and VAPID key
//         const token = await getToken(messaging, {
//             vapidKey: 'BBTGunMGZFIofVj7mHwwKGvVfQowCO8KQysF-AHkpLku-iLiT7NZMzq1roh07WBEDqha5eND4o8RwTYE703hx_c'
//         })
//         console.log('token::', token) // Log the generated token
//     } else {
//         // If permission is not granted, do nothing
//     }
// }