// actions.js
// Action types for side menu fetching request, success, and failure
export const FETCH_SIDEMENU_REQUEST = "FETCH_SIDEMENU_REQUEST";
export const FETCH_SIDEMENU_SUCCESS = "FETCH_SIDEMENU_SUCCESS";
export const FETCH_SIDEMENU_FAILURE = "FETCH_SIDEMENU_FAILURE";

// Action creator for side menu fetching request
export const fetchSideMenuRequest = (payload) => ({
  type: FETCH_SIDEMENU_REQUEST,
  payload
});

// Action creator for side menu fetching success
export const fetchSideMenuSuccess = (sideMenu) => ({
  type: FETCH_SIDEMENU_SUCCESS,
  payload: sideMenu,
});

// Action creator for side menu fetching failure
export const fetchSideMenuFailure = (error) => ({
  type: FETCH_SIDEMENU_FAILURE,
  payload: error,
});
