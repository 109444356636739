// sagas.js
import { takeLatest, put, call } from "redux-saga/effects"; // Import necessary functions from Redux Saga
import { SERIESSUBDATA } from "../../../utility/constants"; // Import constant for API endpoint
import axios from "axios"; // Import Axios for making HTTP requests
import {
  // Import action types and action creators for handling series subdata
  FETCH_SERIES_SUBDATA_REQUEST,
  fetchSeriesSubdataSuccess,
  fetchSeriesSubdataFailure,
} from "../../actions/seriesSubdataActions"; // Import action creators for success and failure cases

function* fecthSeriesSub(action) {
  try {
    let type = action.payload.catType; // Extract category type from action payload
    let keyvalue = action.payload.keyvalue; // Extract key value from action payload
    const subSeriesFormdata = new FormData(); // Create new FormData object for API request

    // Check if category type is "ser" (series) or not
    if (type === "ser") {
      subSeriesFormdata.append("categoryid", keyvalue); // Append category ID to FormData if it's a series
    } else {
      // Append entry ID to FormData if it's not a series
      // Note: This part is currently commented out in the original code
      // subSeriesFormdata.append("entryid", keyvalue);
    }

    // Make POST request to the SERIESSUBDATA endpoint with FormData
    const response = yield call(axios.post, SERIESSUBDATA, subSeriesFormdata, {
      // Include token from local storage in the request headers
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    // Check if the response is successful and status is "Success"
    if (response.status === 200 && response.data.status === "Success") {
      // Dispatch action to handle successful fetch with response data
      yield put(fetchSeriesSubdataSuccess(response.data));
    }
  } catch (error) {
    // Dispatch action to handle fetch failure with error message
    yield put(fetchSeriesSubdataFailure(error.message));
  }
}

// Saga watcher function to listen for FETCH_SERIES_SUBDATA_REQUEST action
export function* SubSeriesSaga() {
  yield takeLatest(FETCH_SERIES_SUBDATA_REQUEST, fecthSeriesSub);
}
// Export the saga watcher function as default
export default SubSeriesSaga;
