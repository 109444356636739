// actions.js

// Define action types for fetching a single video
export const FETCH_VIDEO_REQUEST = "FETCH_VIDEO_REQUEST";
export const FETCH_VIDEO_SUCCESS = "FETCH_VIDEO_SUCCESS";
export const FETCH_VIDEO_FAILURE = "FETCH_VIDEO_FAILURE";

// Action creator for fetch request
export const fetchVideo = (payload) => ({
  type: FETCH_VIDEO_REQUEST,
  payload,
});

// Action creator for fetch success
export const fetchVideoSuccess = (videoData) => ({
  type: FETCH_VIDEO_SUCCESS,
  payload: videoData,
});

// Action creator for fetch failure
export const fetchVideoFailure = (error) => ({
  type: FETCH_VIDEO_FAILURE,
  payload: error,
});
