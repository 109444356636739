// getTokenActions.js
// Action types for top menu fetching request, success, and failure
export const FETCH_COMMONDATA_REQUEST = "FETCH_COMMONDATA_REQUEST";
export const FETCH_COMMONDATA_SUCCESS = "FETCH_COMMONDATA_SUCCESS";
export const FETCH_COMMONDATA_FAILURE = "FETCH_COMMONDATA_FAILURE";

// Action creator for top menu fetching request
export const fetchCommonDataRequest = (payload) => ({
  type: FETCH_COMMONDATA_REQUEST,
  payload,
});

// Action creator for top menu fetching success
export const fetchCommonDataSuccess = (commonData) => ({
  type: FETCH_COMMONDATA_SUCCESS,
  payload: commonData,
});

// Action creator for top menu fetching failure
export const fetchCommonDataFailure = (error) => ({
  type: FETCH_COMMONDATA_FAILURE,
  payload: error,
});
