// Import the action types for fetching IP data
import {
  FETCH_IP_REQUEST,    // Action type for requesting IP data
  FETCH_IP_SUCCESS,    // Action type for successful IP data retrieval
  FETCH_IP_FAILURE,    // Action type for IP data retrieval failure
} from '../../actions/ipapiActions';  // Importing action types from the respective action file

// Define the initial state for the reducer
const initialState = {
  userCurrentLocation: {}, // Initial value for userCurrentLocation is an empty object
  // isLoading: false,       // Uncomment these lines if you want to handle loading state
  // isError: null,          // Uncomment these lines if you want to handle error state
};

// Define the reducer function
const reducer = (state = initialState, action) => {
  // Use a switch statement to handle different action types
  switch (action.type) {
    case FETCH_IP_REQUEST:
      // When the FETCH_IP_REQUEST action is dispatched
      return {
        ...state,
        // isLoading: true,    // Uncomment this line if you want to handle loading state
      };
    case FETCH_IP_SUCCESS:
      // When the FETCH_IP_SUCCESS action is dispatched
      return {
        ...state,
        // isLoading: false,                     // Uncomment this line if you want to handle loading state
        userCurrentLocation: action.payload,   // Update userCurrentLocation with the payload from the action
      };
    case FETCH_IP_FAILURE:
      // When the FETCH_IP_FAILURE action is dispatched
      return {
        ...state,
        // isLoading: false,                     // Uncomment this line if you want to handle loading state
        // isError: action.payload,             // Uncomment this line if you want to handle error state
      };
    default:
      // If none of the above action types match, return the current state
      return state;
  }
};

// Export the reducer as the default export
export default reducer;
