// Define a constant for the action type FETCH_PRAYER_REQUEST
export const FETCH_PRAYER_REQUEST = "FETCH_PRAYER_REQUEST";

// Define a constant for the action type FETCH_PRAYER_SUCCESS
export const FETCH_PRAYER_SUCCESS = "FETCH_PRAYER_SUCCESS";

// Define a constant for the action type FETCH_PRAYER_FAILURE
export const FETCH_PRAYER_FAILURE = "FETCH_PRAYER_FAILURE";

// Action creator function for FETCH_PRAYER_REQUEST
// It takes a payload as an argument and returns an action object
export const fetchPrayerRequest = (payload) => ({
  type: FETCH_PRAYER_REQUEST, // Set the action type to FETCH_PRAYER_REQUEST
  payload, // Pass the payload as is
});

// Action creator function for FETCH_PRAYER_SUCCESS
// It takes prayerData as an argument and returns an action object
export const fetchPrayerSuccess = (prayerData) => ({
  type: FETCH_PRAYER_SUCCESS, // Set the action type to FETCH_PRAYER_SUCCESS
  payload: prayerData, // Set the payload to the prayerData argument
});

// Action creator function for FETCH_PRAYER_FAILURE
// It takes error as an argument and returns an action object
export const fetchPrayerFailure = (error) => ({
  type: FETCH_PRAYER_FAILURE, // Set the action type to FETCH_PRAYER_FAILURE
  payload: error, // Set the payload to the error argument
});