
import React, { useContext, useEffect, useState } from 'react';
import AudioPlayerComponent from '../AudioPlayer';
import { SHORTLINKAPI } from "../../utility/constants";
import axios from "axios";
import { MyContext } from '../../PageRouters/layouts/publicLayout';
import { TfiAngleUp } from "react-icons/tfi";
import { Button } from '@mui/material';
import { IoCloseOutline } from "react-icons/io5";

import { Link } from "react-router-dom";
import { PiShareFatLight } from "react-icons/pi";
import {
    FacebookShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
} from "react-share";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import Facebook from '@mui/icons-material/Facebook';


const RadioPlayer = ({ view }) => {
    console.log('view::', view)

    const context = useContext(MyContext);
    // console.log("contextaudioFiles",context.audioFiles)
    const [state, setState] = useState({
        isPlayAudio: false,
        playIndex: context.playSongIndex,
        isShowingAudioPlayer: false,
        shareWhatsappUrl: '',
        shareFacebookUrl: '',
        shareImage: '',
        shareEntryId: '',
        shareImage: '',
        isOpenShare: false,
        playSong: '',

    });

    const radioCategoryDataArray = [];
    let radioCatObject = {}
    // using forEach
    context.audioFiles.forEach(function (item, key) {
        radioCatObject = {
            "trailer_data": [],
            "name": item.title,
            "ispremium": 0,
            "entryid": item.entryid,
            "thumburl": {
                "size_1080_835": item.img,
                "v_thumburl": item.img,
                "h_thumburl": item.img
            },
            "description": item.description,
            "video_type": "audio",
            "download_url": item.song,
            "duration": 0,
            "sub_count": 0
        }
        radioCategoryDataArray.push(radioCatObject);
    })

    // console.log("radioCategoryDataArray", radioCategoryDataArray);

    const { shareEntryId, playSong, playIndex } = state
    useEffect(() => {
        generateShareUrlSocial(context.audioFiles)
        // console.log('context.audioFiles2', context.audioFiles)
        // console.log('context useEffect', context.isOpenRadio)
        if (context.isOpenRadio === true) {
            setState((prevState) => ({
                ...prevState,
                isShowingAudioPlayer: context.isOpenRadio,
                shareEntryId: context.audioFiles[context.playSongIndex]?.entryid,
                playSong: context.audioFiles[playIndex]?.song
            }));
        } else {

        }
        // setTimeout(() => {

        // }, 3000);
    }, [context.isOpenRadio, context.view, context.audioFiles, context.audioFiles[context.playSongIndex]?.song])
    const generateShareUrlSocial = (data) => {
        // console.log('data', data)
        // let pathname = window.location.hostname
        let mainuri = "https://shalomtv.page.link?";
        let exactpath = `https://ott.shalomtv.tv/radioview/cat/${data[0]?.title.slice(0, -1)
            .replace(/\s/g, "")
            .toLowerCase()}/${data[0]?.entryid ? data[0]?.entryid : shareEntryId}`;
        // let exactpath = `http://localhost:3000/radioview/cat/${data[0]?.title
        //     .replace(/\s/g, "")
        //     .toLowerCase()}/${data[0]?.entryid ? data[0]?.entryid : shareEntryId}`;
        // let exactpath = 'https://ott.shalomtv.tv/watch/cat/nadhanodoppam/s_o9nzfeg1';
        // console.log("path",data[0]?.title.slice(0, -1)); 
        let iosUrl = "com.shalom.malayalam.shalomworld.tvos";
        let androidurl = "com.shalommediaonline.shalomtv";
        let sharelink = "https://shalomtv.page.link/share?entryid=";
        let iosFalbackLink = "https://apps.apple.com/in/app/shalomtv-live/id1091434975"
        let iandroidFalbackLink = "https://play.google.com/store/apps/details?id=com.shalommediaonline.shalomtv"
        let imagepath1 = data[0]?.img;
        let entryId = data[0]?.entryid
        let imagepath2 = data[0]?.img + "/width=400/height=400/quality=40";
        let iosAppId = "1091434975"
        let platformLink = "https://ott.shalomtv.tv/share?entryid="

        // console.log('data', data)

        let generateShareLink = (imagePath) => {
            return encodeURI(
                mainuri + "sd=" + context.audioFiles[context.playSongIndex]?.description + "&si=" + imagePath + "&st=" + context.audioFiles[context.playSongIndex]?.title + "&apn=" + androidurl + "&efr=1" + "&ibi=" + iosUrl + "&link=" + platformLink + `${entryId}+shareaudio` + "&ofl=" + exactpath + "?contenttype=shareaudio" + "&afl=" + iandroidFalbackLink + "&isi=" + iosAppId
            );
        };

        let facebookShare = generateShareLink(imagepath1);
        let whatsappShare = generateShareLink(imagepath2);
        shortLinkFacebook(facebookShare);
        shortlinkWhatsapp(whatsappShare);
    };

    const shortLinkFacebook = (url) => {
        axios
            .post(SHORTLINKAPI, {
                longDynamicLink: url,
                suffix: {
                    option: "SHORT",
                },
            })
            .then(function (response) {
                if (response.status === 200) {
                    // console.log("facebookShare", response.data.shortLink);

                    setState((prevState) => ({
                        ...prevState,
                        // shareFacebookUrl: "",
                        // shareWhatsappUrl: "",
                        shareFacebookUrl: response.data.shortLink,
                    }));
                }
            })
            .catch(function (error) { });
    };
    const shortlinkWhatsapp = (url) => {
        axios
            .post(SHORTLINKAPI, {
                longDynamicLink: url,
                suffix: {
                    option: "SHORT",
                },
            })
            .then(function (response) {
                if (response.status === 200) {
                    // console.log("whatsappShare", response.data.shortLink);

                    setState((prevState) => ({
                        ...prevState,
                        // shareFacebookUrl: "",
                        // shareWhatsappUrl: "",
                        shareWhatsappUrl: response.data.shortLink,
                    }));
                }
            })
            .catch(function (error) { });
    };

    const nextSong = (index) => {
        if (index !== context.audioFiles.length - 1) {
            setState((prevState) => ({
                ...prevState,
                playIndex: index + 1,
            }));
            // setplayIndex(index + 1)

        }
        if (index === context.audioFiles.length - 1) {
            setState((prevState) => ({
                ...prevState,
                playIndex: 0,
            }));
            // setplayIndex(0)
        }

    }

    const prevSong = (index) => {
        if (index === 0) {
            setState((prevState) => ({
                ...prevState,
                playIndex: 0,
            }));
            // setplayIndex(0)
        } else {
            // setplayIndex(index - 1)
            setState((prevState) => ({
                ...prevState,
                playIndex: index - 1,
            }));
        }

    }


    useEffect(() => {
        context.setplaySongIndex(state.playIndex)
    }, [state.playIndex])


    const toggleShare = () => {
        setState((prevState) => ({
            ...prevState,
            isOpenShare: !state.isOpenShare

        }));
    }
    // console.log('context', context)
    return (
        <div className={`radiopPlayer ${view} ${state.isShowingAudioPlayer === true && 'openRadioPlayer'}`}>
            {
                context.audioFiles.length > 0 &&
                <div className='audioWrapper d-flex align-items-center flex-column'>
                    <div className='info d-flex align-items-center full flex-column'>
                        <div className='img-wrapper'>
                            <img src={context.audioFiles[context.playSongIndex]?.img} className='w-100' />
                        </div>
                        {/* {console.log('props', context.audioFiles[context.playSongIndex]?)} */}
                        <div className='des px-3'>
                            <h6 className='text-white'>
                                {context.audioFiles[context.playSongIndex]?.title}
                                {context.audioFiles[context.playSongIndex]?.isradio === true ? <span className='liveWrap'> <span class="livepulse"></span>LIVE </span> : ''}
                            </h6>
                            {
                                //     <p>{context.audioFiles[context.playSongIndex]?.subTitle}</p>
                                // <span className='text-red'>{context.audioFiles[context.playSongIndex]?.label}</span>
                            }

                            {
                                view === "minimize" &&
                                <div className='actions d-flex align-items-center'>
                                    {context.audioFiles[context.playSongIndex]?.isradio === false && <div className={`speed-dial ${state.isOpenShare === true ? 'toggle' : ''}`}>
                                        <Button className='mx-2 toggle bg-share' onClick={toggleShare}><PiShareFatLight /></Button>
                                        <ul className="dials">
                                            <li><FacebookShareButton
                                                url={
                                                    state.shareFacebookUrl !== undefined &&
                                                        state.shareFacebookUrl !== null &&
                                                        state.shareFacebookUrl !== ""
                                                        ? state.shareFacebookUrl
                                                        : ""
                                                }
                                                quote={context.audioFiles[context.playSongIndex]?.description}
                                            >
                                                <FacebookIcon style={{ color: '#38529a' }} />
                                            </FacebookShareButton></li>
                                            <li>
                                                <WhatsappShareButton
                                                    url={
                                                        state.shareWhatsappUrl !== undefined &&
                                                            state.shareWhatsappUrl !== null &&
                                                            state.shareWhatsappUrl !== ""
                                                            ? state.shareWhatsappUrl
                                                            : ""
                                                    }
                                                    title={context.audioFiles[context.playSongIndex]?.description}
                                                >
                                                    <WhatsAppIcon style={{ color: '#4dc85a' }} />
                                                </WhatsappShareButton>
                                            </li>
                                            <li>
                                                <LinkedinShareButton
                                                    url={
                                                        state.shareWhatsappUrl !== undefined &&
                                                            state.shareWhatsappUrl !== null &&
                                                            state.shareWhatsappUrl !== ""
                                                            ? state.shareWhatsappUrl
                                                            : ""
                                                    }
                                                    title={context.audioFiles[context.playSongIndex]?.description}
                                                >
                                                    <LinkedInIcon style={{ color: '#0076b3' }} />
                                                </LinkedinShareButton>
                                            </li>
                                            <li>
                                                <TwitterShareButton
                                                    url={
                                                        state.shareWhatsappUrl !== undefined &&
                                                            state.shareWhatsappUrl !== null &&
                                                            state.shareWhatsappUrl !== ""
                                                            ? state.shareWhatsappUrl
                                                            : ""
                                                    }
                                                    title={context.audioFiles[context.playSongIndex]?.description}
                                                >
                                                    <TwitterIcon style={{ color: '#1c96e8' }} />
                                                </TwitterShareButton>
                                            </li>
                                            <li>
                                                <TelegramShareButton
                                                    url={
                                                        state.shareWhatsappUrl !== undefined &&
                                                            state.shareWhatsappUrl !== null &&
                                                            state.shareWhatsappUrl !== ""
                                                            ? state.shareWhatsappUrl
                                                            : ""
                                                    }
                                                    title={context.audioFiles[context.playSongIndex]?.description}
                                                >
                                                    <TelegramIcon style={{ color: '#2496d1' }} />
                                                </TelegramShareButton>
                                            </li>
                                        </ul>
                                    </div>}
                                    {context.audioFiles[context.playSongIndex]?.isradio === false && <Link to={
                                        {
                                            pathname: `${context.audioFiles[context.playSongIndex]?.link}`,
                                            state: {
                                                play: context.playSongIndex
                                            }
                                        }
                                    }> <Button className='mx-2'><TfiAngleUp /></Button></Link>}
                                    {/* {context.audioFiles[context.playSongIndex]?.isradio === false && <Link to={context.audioFiles[context.playSongIndex]?.link}><Button className='mx-2'><TfiAngleUp /></Button></Link>} */}
                                    {/* {context.audioFiles[context.playSongIndex]?.isradio === false && <Link to={{ pathname: context.audioFiles[context.playSongIndex]?.link, state: context.audioFiles }}><Button className='mx-2'><TfiAngleUp /></Button></Link>} */}
                                    {/* {context.audioFiles[context.playSongIndex]?.isradio === false && <Link to={{
                                        pathname: context.audioFiles[context.playSongIndex]?.link, state: {
                                            "title_tag_name": sessionStorage.getItem("cat_titletagname") ? sessionStorage.getItem("cat_titletagname") : "Related Audio",
                                            // "search_tag" : context.audioFiles.length > 0 ? context.audioFiles : []
                                            "search_tag": radioCategoryDataArray
                                        }
                                    }}><Button className='mx-2'><TfiAngleUp /></Button></Link>} */}

                                    <Button onClick={() => {
                                        context.setisOpenRadio(false);
                                        document.body.classList.remove('radioOpen')
                                        context.setplaySongIndex(null)
                                        context.setAudeioFiles([{}])
                                    }}><IoCloseOutline /></Button>
                                </div>
                            }


                        </div>
                    </div>
                    {console.log('context', context)}
                    <div className='audio full'>
                        <AudioPlayerComponent data={context.audioFiles} nextSong={nextSong} prevSong={prevSong} />
                        {
                            view === "full" &&
                            <div className='actions d-flex align-items-center fullRadioActions'>
                                {context.audioFiles[context.playSongIndex]?.isradio === false ? <div className={`speed-dial ${state.isOpenShare === true ? 'toggle' : ''}`}>
                                    <Button className='mx-2 toggle bg-share' onClick={toggleShare}><PiShareFatLight /></Button>
                                    <ul className="dials">
                                        <li><FacebookShareButton
                                            url={
                                                state.shareFacebookUrl !== undefined &&
                                                    state.shareFacebookUrl !== null &&
                                                    state.shareFacebookUrl !== ""
                                                    ? state.shareFacebookUrl
                                                    : ""
                                            }
                                            quote={context.audioFiles[context.playSongIndex]?.description}
                                        >
                                            <FacebookIcon style={{ color: '#38529a' }} />
                                        </FacebookShareButton></li>
                                        <li>
                                            <WhatsappShareButton
                                                url={
                                                    state.shareWhatsappUrl !== undefined &&
                                                        state.shareWhatsappUrl !== null &&
                                                        state.shareWhatsappUrl !== ""
                                                        ? state.shareWhatsappUrl
                                                        : ""
                                                }
                                                title={context.audioFiles[context.playSongIndex]?.description}
                                            >
                                                <WhatsAppIcon style={{ color: '#4dc85a' }} />
                                            </WhatsappShareButton>
                                        </li>
                                        <li>
                                            <LinkedinShareButton
                                                url={
                                                    state.shareWhatsappUrl !== undefined &&
                                                        state.shareWhatsappUrl !== null &&
                                                        state.shareWhatsappUrl !== ""
                                                        ? state.shareWhatsappUrl
                                                        : ""
                                                }
                                                title={context.audioFiles[context.playSongIndex]?.description}
                                            >
                                                <LinkedInIcon style={{ color: '#0076b3' }} />
                                            </LinkedinShareButton>
                                        </li>
                                        <li>
                                            <TwitterShareButton
                                                url={
                                                    state.shareWhatsappUrl !== undefined &&
                                                        state.shareWhatsappUrl !== null &&
                                                        state.shareWhatsappUrl !== ""
                                                        ? state.shareWhatsappUrl
                                                        : ""
                                                }
                                                title={context.audioFiles[context.playSongIndex]?.description}
                                            >
                                                <TwitterIcon style={{ color: '#1c96e8' }} />
                                            </TwitterShareButton>
                                        </li>
                                        <li>
                                            <TelegramShareButton
                                                url={
                                                    state.shareWhatsappUrl !== undefined &&
                                                        state.shareWhatsappUrl !== null &&
                                                        state.shareWhatsappUrl !== ""
                                                        ? state.shareWhatsappUrl
                                                        : ""
                                                }
                                                title={context.audioFiles[context.playSongIndex]?.description}
                                            >
                                                <TelegramIcon style={{ color: '#2496d1' }} />
                                            </TelegramShareButton>
                                        </li>
                                    </ul>
                                </div> : ""}


                            </div>
                        }
                    </div>

                    {
                        view === "minimize" &&
                        <div className='actions d-flex align-items-center res-hide'>
                            {context.audioFiles[context.playSongIndex]?.isradio === false && <div className={`speed-dial ${state.isOpenShare === true ? 'toggle' : ''}`}>
                                <Button className='mx-2 toggle bg-share' onClick={toggleShare}><PiShareFatLight /></Button>
                                <ul className="dials">
                                    <li><FacebookShareButton
                                        url={
                                            state.shareFacebookUrl !== undefined &&
                                                state.shareFacebookUrl !== null &&
                                                state.shareFacebookUrl !== ""
                                                ? state.shareFacebookUrl
                                                : ""
                                        }
                                        quote={context.audioFiles[context.playSongIndex]?.description}
                                    >
                                        <FacebookIcon style={{ color: '#38529a' }} />
                                    </FacebookShareButton></li>
                                    <li>
                                        <WhatsappShareButton
                                            url={
                                                state.shareWhatsappUrl !== undefined &&
                                                    state.shareWhatsappUrl !== null &&
                                                    state.shareWhatsappUrl !== ""
                                                    ? state.shareWhatsappUrl
                                                    : ""
                                            }
                                            title={context.audioFiles[context.playSongIndex]?.description}
                                        >
                                            <WhatsAppIcon style={{ color: '#4dc85a' }} />
                                        </WhatsappShareButton>
                                    </li>
                                    <li>
                                        <LinkedinShareButton
                                            url={
                                                state.shareWhatsappUrl !== undefined &&
                                                    state.shareWhatsappUrl !== null &&
                                                    state.shareWhatsappUrl !== ""
                                                    ? state.shareWhatsappUrl
                                                    : ""
                                            }
                                            title={context.audioFiles[context.playSongIndex]?.description}
                                        >
                                            <LinkedInIcon style={{ color: '#0076b3' }} />
                                        </LinkedinShareButton>
                                    </li>
                                    <li>
                                        <TwitterShareButton
                                            url={
                                                state.shareWhatsappUrl !== undefined &&
                                                    state.shareWhatsappUrl !== null &&
                                                    state.shareWhatsappUrl !== ""
                                                    ? state.shareWhatsappUrl
                                                    : ""
                                            }
                                            title={context.audioFiles[context.playSongIndex]?.description}
                                        >
                                            <TwitterIcon style={{ color: '#1c96e8' }} />
                                        </TwitterShareButton>
                                    </li>
                                    <li>
                                        <TelegramShareButton
                                            url={
                                                state.shareWhatsappUrl !== undefined &&
                                                    state.shareWhatsappUrl !== null &&
                                                    state.shareWhatsappUrl !== ""
                                                    ? state.shareWhatsappUrl
                                                    : ""
                                            }
                                            title={context.audioFiles[context.playSongIndex]?.description}
                                        >
                                            <TelegramIcon style={{ color: '#2496d1' }} />
                                        </TelegramShareButton>
                                    </li>
                                </ul>
                            </div>}

                            {context.audioFiles[context.playSongIndex]?.isradio === false && <Link to={
                                {
                                    pathname: `${context.audioFiles[context.playSongIndex]?.link}`,
                                    state: {
                                        play: context.playSongIndex
                                    }
                                }
                            }><Button className='mx-2'><TfiAngleUp /></Button></Link>}
                            {/* {context.audioFiles[context.playSongIndex]?.isradio === false && <Link to={{
                                pathname: context.audioFiles[context.playSongIndex]?.link, state: {
                                    "title_tag_name": sessionStorage.getItem("cat_titletagname") ? sessionStorage.getItem("cat_titletagname") : "Related Audio",
                                    //"search_tag" : context.audioFiles.length > 0 ? context.audioFiles : []
                                    "search_tag": radioCategoryDataArray
                                }
                            }}><Button className='mx-2'><TfiAngleUp /></Button></Link>} */}
                            <Button onClick={() => {
                                context.setisOpenRadio(false);
                                document.body.classList.remove('radioOpen')
                                context.setplaySongIndex(null)
                                context.setAudeioFiles([{}])
                            }}><IoCloseOutline /></Button>
                        </div>
                    }



                </div>
            }
        </div >
    )
}

export default RadioPlayer;