// sagas.js
import { takeLatest, put, call } from "redux-saga/effects";
import { NOTIFICATION } from "../../../utility/constants";
import axios from "axios";
import {
  FETCH_PUSHNOTIFICATION_REQUEST,
  fetchPushNotificationSuccess,
  fetchPushNotificationFailure,
} from "../../actions/nortificationActions";

function* fecthPushNortification(action) {
  try {
    const dynamicpagePayload = new FormData();
    dynamicpagePayload.append("state", action.payload.state);
    dynamicpagePayload.append("partnerid", action.payload.partnerid);
    dynamicpagePayload.append("badges", action.payload.badges);
    dynamicpagePayload.append("uuid", action.payload.uuid);
    dynamicpagePayload.append("tag", action.payload.tag);
    dynamicpagePayload.append("app_token", action.payload.app_token);
    dynamicpagePayload.append("device", action.payload.device);
    dynamicpagePayload.append("userid", action.payload.userid);
    dynamicpagePayload.append("os", action.payload.os);
    dynamicpagePayload.append("device_model", action.payload.device_model);
    const response = yield call(axios.post, NOTIFICATION, dynamicpagePayload, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200 && response.data.status === "Success") {
      yield put(fetchPushNotificationSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchPushNotificationFailure(error.message));
  }
}

export function* NotificationSaga() {
  yield takeLatest(FETCH_PUSHNOTIFICATION_REQUEST, fecthPushNortification);
}
export default NotificationSaga;
