// actions.js

// Action types for OTP generation request, success, and failure
export const GENERATE_OTP_REQUEST = "GENERATE_OTP_REQUEST";
export const GENERATE_OTP_SUCCESS = "GENERATE_OTP_SUCCESS";
export const GENERATE_OTP_FAILURE = "GENERATE_OTP_FAILURE";

// Action creator for OTP generation request
export const generateOTPRequest = (payload) => ({
  type: GENERATE_OTP_REQUEST,
  payload,
});

// Action creator for OTP generation success
export const generateOTPSuccess = (generateuserOtp) => ({
  type: GENERATE_OTP_SUCCESS,
  payload: generateuserOtp,
});

// Action creator for OTP generation failure
export const generateOTPFailure = (error) => ({
  type: GENERATE_OTP_FAILURE,
  payload: error,
});
