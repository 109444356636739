// actions.js
// Action types for recommended data fetching request, success, and failure
export const FETCH_RECOMMENDED_REQUEST = "FETCH_RECOMMENDED_REQUEST";
export const FETCH_RECOMMENDED_SUCCESS = "FETCH_RECOMMENDED_SUCCESS";
export const FETCH_RECOMMENDED_FAILURE = "FETCH_RECOMMENDED_FAILURE";

// Action creator for recommended data fetching request
export const fetchRecommendedRequest = (payload) => ({
  type: FETCH_RECOMMENDED_REQUEST,
  payload,
});

// Action creator for recommended data fetching success
export const fetchRecommendedSuccess = (recommendedData) => ({
  type: FETCH_RECOMMENDED_SUCCESS,
  payload: recommendedData,
});

// Action creator for recommended data fetching failure
export const fetchRecommendedFailure = (error) => ({
  type: FETCH_RECOMMENDED_FAILURE,
  payload: error,
});
