// Import the action types for image upload actions
import {
  UPLOAD_IMAGE_REQUEST,   // Action type for requesting image upload
  UPLOAD_IMAGE_SUCCESS,   // Action type for successful image upload
  UPLOAD_IMAGE_FAILURE,   // Action type for image upload failure
} from "../../actions/imageUploadActions";  // Importing action types from the respective action file

// Define the initial state for the reducer
const initialState = {
  imageUrl: null,   // Initial value for imageUrl is null
  isLoading: false, // Initial value for isLoading is false
  isError: null,    // Initial value for isError is null
};

// Define the imageUploadReducers function
const imageUploadReducers = (state = initialState, action) => {
  // Use a switch statement to handle different action types
  switch (action.type) {
    case UPLOAD_IMAGE_REQUEST:
      // When the UPLOAD_IMAGE_REQUEST action is dispatched
      return {
        ...state,
        isLoading: true,    // Set isLoading to true
        isError: null,      // Reset isError to null
      };
    case UPLOAD_IMAGE_SUCCESS:
      // When the UPLOAD_IMAGE_SUCCESS action is dispatched
      return {
        ...state,
        imageUrl: action.payload,  // Update imageUrl with the payload from the action
        isLoading: false,          // Set isLoading to false
        isError: null,             // Reset isError to null
      };
    case UPLOAD_IMAGE_FAILURE:
      // When the UPLOAD_IMAGE_FAILURE action is dispatched
      return {
        ...state,
        imageUrl: null,            // Reset imageUrl to null
        isLoading: false,          // Set isLoading to false
        isError: action.payload,   // Update isError with the payload from the action
      };
    default:
      // If none of the above action types match, return the current state
      return state;
  }
};

// Export the imageUploadReducers as the default export
export default imageUploadReducers;
