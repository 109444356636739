// Import the action types for language preview
import {
  FETCH_LANGUAGEPREVIEW_REQUEST,   // Action type for requesting language preview data
  FETCH_LANGUAGEPREVIEW_SUCCESS,   // Action type for successful language preview data retrieval
  FETCH_LANGUAGEPREVIEW_FAILURE,   // Action type for language preview data retrieval failure
} from "../../actions/languagePreviewActions";

// Define the initial state for the reducer
const initialState = {
  languagepreviewData: null,  // Initial value for languagepreviewData is null
  isLoading: false,            // Initial value for isLoading is false
  isError: null,               // Initial value for isError is null
};

// Define the LanguagepreviewReducer function
const LanguagepreviewReducer = (state = initialState, action) => {
  // Use a switch statement to handle different action types
  switch (action.type) {
    case FETCH_LANGUAGEPREVIEW_REQUEST:
      // When the FETCH_LANGUAGEPREVIEW_REQUEST action is dispatched
      return {
        ...state,
        isLoading: true,  // Set isLoading to true
        isError: null,    // Reset isError to null
      };
    case FETCH_LANGUAGEPREVIEW_SUCCESS:
      // When the FETCH_LANGUAGEPREVIEW_SUCCESS action is dispatched
      return {
        ...state,
        languagepreviewData: action.payload, // Update languagepreviewData with the payload from the action
        isLoading: false,                    // Set isLoading to false
        isError: null,                       // Reset isError to null
      };
    case FETCH_LANGUAGEPREVIEW_FAILURE:
      // When the FETCH_LANGUAGEPREVIEW_FAILURE action is dispatched
      return {
        ...state,
        isLoading: false,      // Set isLoading to false
        isError: action.payload, // Update isError with the payload from the action
      };
    default:
      // If none of the above action types match, return the current state
      return state;
  }
};

// Export the LanguagepreviewReducer as the default export
export default LanguagepreviewReducer;
