// sagas.js
import { takeLatest, call, put } from "redux-saga/effects";
import {USERLOCATION} from '../../../utility/constants'
import axios from "axios";
import {
  FETCH_IP_REQUEST,
  fetchIPSuccess,
  fetchIPFailure,
} from "../../actions/ipapiActions";

function* fetchIPData() {
  let existingToken = localStorage.getItem('token') !== undefined && localStorage.getItem('token') !== 'undefined' && localStorage.getItem('token')
  try {
    if (!existingToken) {
      const response = yield call(axios.get, USERLOCATION);
      yield put(fetchIPSuccess(response.data));
    } else {
    }
  } catch (error) {
    yield put(fetchIPFailure(error.message));
  }
}

function* ipapiSaga() {
  yield takeLatest(FETCH_IP_REQUEST, fetchIPData);
}

export default ipapiSaga;
