// updateProfileActions.js

// Define action types for updating user profile
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";

// Action creator for updating user profile request
export const updateProfile = (payload) => ({
  type: UPDATE_USER_PROFILE,
  payload,
});

// Action creator for updating user profile success
export const updateProfileSuccess = (updateProfile) => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  payload: updateProfile,
});

// Action creator for updating user profile failure
export const updateProfileFailure = (error) => ({
  type: UPDATE_USER_PROFILE_FAILURE,
  payload: error,
});
