
import React, { useState, useEffect, useContext } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { MyContext } from '../../PageRouters/layouts/publicLayout';

const AudioPlayerComponent = (props) => {
  // console.log("audioprops", props)

  const [state, setState] = useState({
    audioFile: props.data,
    audioIndex: 0,
    loop : false
  });

  const {
    loop
  } = state;

  const context = useContext(MyContext);

  const onChangeNext = () => {
    if(context?.audioFiles[context?.playSongIndex]?.song ? context?.audioFiles[context?.playSongIndex]?.song : '' === ''){
      setState({ ...state, loop: true})
      return
    }
    
    if (context?.playSongIndex !== context?.playSongIndex.length - 1) {
      setState((prevState) => ({
        ...prevState,
        audioIndex: state.audioIndex + 1,
      }));
      let value = context?.playSongIndex + 1
      context.setplaySongIndex(value)

    }
    if (context?.playSongIndex === context?.playSongIndex.length - 1) {
      setState((prevState) => ({
        ...prevState,
        audioIndex: 0
      }));
      let value = context?.playSongIndex - 1
      context.setplaySongIndex(value)
    }

  }

  const onChangePrev = () => {
    if (context?.playSongIndex === 0) {
      setState((prevState) => ({
        ...prevState,
        audioIndex: 0
      }));
      context.setplaySongIndex(0)
    } else {
      setState((prevState) => ({
        ...prevState,
        audioIndex: state.audioIndex - 1
      }));
      let value = context?.playSongIndex - 1
      context.setplaySongIndex(value)
    }

  }

  // useEffect(() => {


  // }, [context.playSongIndex])

  // console.log("abhi", context?.audioFiles[context?.playSongIndex]?.song ? context?.audioFiles[context?.playSongIndex]?.song : '')

  return (
    <AudioPlayer
      autoPlay
      showSkipControls={true}
      autoPlayAfterSrcChange={true}
      src={context?.audioFiles[context?.playSongIndex]?.song ? context?.audioFiles[context?.playSongIndex]?.song : ''}
      showJumpControls={false}
      loop={loop}
      //muted={state.isMutedRadioPlayer}
      // onPlay={e => console.log("onPlay")}
      onClickPrevious={(e) => {
        onChangePrev();
        props.prevSong(context?.playSongIndex ? context?.playSongIndex : 0);
      }}
      onClickNext={(e) => {
        onChangeNext();
        props.nextSong(context?.playSongIndex ? context?.playSongIndex : 0);
      }}
      onEnded={() => {
        onChangeNext();
        props.nextSong(context?.playSongIndex ? context?.playSongIndex : 0);
      }}
    // other props here
    />
  )
}

export default AudioPlayerComponent;