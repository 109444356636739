// rootSaga.js

import { all } from "redux-saga/effects";

// Importing saga files...
import ipapiSaga from "../sagas/ipapiSaga"; // Importing IPAPI saga
import tokenSaga from "../sagas/tokenSaga"; // Importing token saga
import sideMenuSaga from "./sideMenuSaga"; // Importing side menu saga
import topMenuSaga from "./topMenuSaga"; // Importing top menu saga
// import dataSaga from "./commonDataSaga"; // Importing common data saga
import fetchFooterMenuSaga from "./fetchFooterMenuSaga"; // Importing fetch footer menu saga
import fecthHomeDataSaga from "./fecthHomeSaga"; // Importing fetch home data saga
import generateOtpSaga from "./generateOtpSaga"; // Importing generate OTP saga
import getVerifyOtpSaga from "./getVerifyOtpSaga"; // Importing get verify OTP saga
import getProfileSaga from "./profileSaga"; // Importing get profile saga
import updatedProfileSaga from "./updateProfileSaga"; // Importing updated profile saga
import imageUploadSaga from "./imageUploadSaga"; // Importing image upload saga
import videoSaga from "./videoSaga"; // Importing video saga
import relatedVideoSaga from "./relatedVideoSaga"; // Importing related video saga
import EpisodeSaga from "./episodeSaga"; // Importing episode saga
import DeviceManagementSaga from "./deviceManagementSaga"; // Importing device management saga
import RemoveDeviceManagementSaga from "./removeDeviceManagementSaga"; // Importing remove device management saga
import RecommendedSaga from "./recommendedSaga"; // Importing recommended saga
import homeCarouselSaga from "./homeCarouselSaga"; // Importing home carousel saga
import viewContinueWatchingSaga from "./continueWatchingSaga/viewSaga"; // Importing view continue watching saga
import deleteContinueWatchingSaga from "./continueWatchingSaga/deleteSaga"; // Importing delete continue watching saga
import viewMyListSaga from "./mylistSaga/viewMylistSaga"; // Importing view my list saga
import deleteMylistSaga from "./mylistSaga/deleteMylistSaga"; // Importing delete my list saga
import preHomePopupSaga from "./preHomePopupSaga"; // Importing pre-home popup saga
import LogoutSaga from "./LogoutSaga"; // Importing logout saga
import StaticPageSaga from "./StaticPageSaga"; // Importing static page saga
import SearchSaga from "./searchSaga"; // Importing search saga
import SearchDataSaga from "./fecthSearchDataSaga"; // Importing fetch search data saga
import LanguagepreviewSaga from "./languagepreviewSaga"; // Importing language preview saga
import LanguageChangeSaga from "./languageChangeSaga"; // Importing language change saga
import TransactionHistorySaga from "./transactionHistorySaga"; // Importing transaction history saga
import SubSeriesSaga from './seriesSubdataSaga'; // Importing sub-series data saga
// import ViewAllSaga from './viewallSaga'; // Importing view all saga
import TvscheduleSaga from './tvscheduleSaga'; // Importing TV schedule saga
import TvScheduletimezoneSaga from './tvscheduletimezoneSaga'; // Importing TV schedule timezone saga
import RegionSaga from './regionSaga'; // Importing region saga
import PrayerSaga from './prayerSaga'; // Importing prayer saga
import CommonSaga from './commonSaga'// Importing common saga
import DynamicPagesSaga from './dynamicPagesSaga'// Importing Dynamic Saga
import NotificationSaga from './nortificationSaga'
import GetViewAllSaga from './getViewallSaga'
// Root saga function that combines all sagas
export default function* rootSaga() {
  yield all([
    // List of all sagas to run concurrently
    ipapiSaga(),
    tokenSaga(),
    sideMenuSaga(),
    topMenuSaga(),
    fetchFooterMenuSaga(),
    fecthHomeDataSaga(),
    // dataSaga(),
    generateOtpSaga(),
    getVerifyOtpSaga(),
    getProfileSaga(),
    updatedProfileSaga(),
    imageUploadSaga(),
    videoSaga(),
    relatedVideoSaga(),
    EpisodeSaga(),
    RecommendedSaga(),
    homeCarouselSaga(),
    // ViewAllSaga(),
    viewMyListSaga(),
    StaticPageSaga(),
    deleteMylistSaga(),
    viewContinueWatchingSaga(),
    deleteContinueWatchingSaga(),
    preHomePopupSaga(),
    TransactionHistorySaga(),
    LogoutSaga(),
    SearchSaga(),
    SearchDataSaga(),
    LanguagepreviewSaga(),
    LanguageChangeSaga(),
    SubSeriesSaga(),
    DeviceManagementSaga(),
    RemoveDeviceManagementSaga(),
    TvscheduleSaga(),
    TvScheduletimezoneSaga(),
    RegionSaga(),
    PrayerSaga(),
    CommonSaga(),
    DynamicPagesSaga(),
    NotificationSaga(),
    GetViewAllSaga(),
  ]);
}
