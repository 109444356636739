// reducers.js
import {
  FETCH_HOME_REQUEST,
  FETCH_HOME_SUCCESS,
  FETCH_HOME_FAILURE,
} from "../../actions/fecthHomeActions";

const initialState = {
  homeData: null,
  isLoading: false,
  isError: null,
};

const getHomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HOME_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: null,
      };
    case FETCH_HOME_SUCCESS:
      return {
        ...state,
        homeData: action.payload,
        isLoading: false,
        isError: null,
      };
    case FETCH_HOME_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };
    default:
      return state;
  }
};

export default getHomeReducer;
