// actions.js
export const DELETE_MYLIST_REQUEST = "DELETE_MYLIST_REQUEST";
export const DELETE_MYLIST_SUCCESS = "DELETE_MYLIST_SUCCESS";
export const DELETE_MYLIST_FAILURE = "DELETE_MYLIST_FAILURE";

export const deleteMyListRequest = (payload) => ({
  type: DELETE_MYLIST_REQUEST,
  payload,
});

export const deleteMylistSuccess = (mylistitemsdelete) => ({
  type: DELETE_MYLIST_SUCCESS,
  payload: mylistitemsdelete,
});

export const deleteMylistFailure = (error) => ({
  type: DELETE_MYLIST_FAILURE,
  payload: error,
});
