// Import the action types from the prayerActions file
import {
  FETCH_PRAYER_REQUEST, // Action type for requesting prayer data
  FETCH_PRAYER_SUCCESS, // Action type for successful prayer data fetch
  FETCH_PRAYER_FAILURE, // Action type for prayer data fetch failure
} from "../../actions/prayerActions";

// Define the initial state for the reducer
const initialState = {
  prayerData: null, // Initial value for prayerData is null
  isLoading: false, // Initial value for isLoading is false
  isError: null, // Initial value for isError is null
};

// Define the PrayerReducer function
const PrayerReducer = (state = initialState, action) => {
  // Use a switch statement to handle different action types
  switch (action.type) {
    case FETCH_PRAYER_REQUEST:
      // When the FETCH_PRAYER_REQUEST action is dispatched
      return {
        ...state, // Spread the current state
        isLoading: true, // Set isLoading to true
        isError: null, // Reset isError to null
      };
    case FETCH_PRAYER_SUCCESS:
      // When the FETCH_PRAYER_SUCCESS action is dispatched
      return {
        ...state, // Spread the current state
        prayerData: action.payload, // Update prayerData with the payload from the action
        isLoading: false, // Set isLoading to false
        isError: null, // Reset isError to null
      };
    case FETCH_PRAYER_FAILURE:
      // When the FETCH_PRAYER_FAILURE action is dispatched
      return {
        ...state, // Spread the current state
        isLoading: false, // Set isLoading to false
        isError: action.payload, // Update isError with the payload from the action
      };
    default:
      // If none of the above action types match, return the current state
      return state;
  }
};

// Export the PrayerReducer as the default export
export default PrayerReducer;
