// sagas.js
import { takeLatest, put, call } from "redux-saga/effects";
import { LANGUAGELIST } from "../../../utility/constants";
import axios from "axios";
import {
  FETCH_LANGUAGEPREVIEW_REQUEST,
  fetchLanguagePreviewSuccess,
  fetchLanguagePreviewFailure,
} from "../../actions/languagePreviewActions";

function* fecthLanguagepreview() {
  try {
    const response = yield call(axios.get, LANGUAGELIST, {
      headers: {
        token: localStorage.getItem("token"),

      },
    });
    // 
    if (response.status === 201 && response.data.status === "Success") {
      yield put(fetchLanguagePreviewSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchLanguagePreviewFailure(error.message));
  }
}

export function* LanguagepreviewSaga() {
  yield takeLatest(FETCH_LANGUAGEPREVIEW_REQUEST, fecthLanguagepreview);
}
export default LanguagepreviewSaga;
