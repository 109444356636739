import React, { createContext, useState } from "react";

// Create a new context called SnackbarContext
const SnackbarContext = createContext();

// Create a provider component for the SnackbarContext
const SnackbarProvider = ({ children }) => {
  // Define state variables for managing Snackbar properties
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("info");
  const [anchorOrigin, setAnchorOrigin] = useState({
    vertical: "bottom",
    horizontal: "center",
  });
  const [autoHideDuration, setAutoHideDuration] = useState(3000);

  // Function to show the Snackbar with specified message and options
  const showSnackbar = (message, options = {}) => {
    const {
      severity = "info",
      anchorOrigin = { vertical: "top", horizontal: "right" },
      autoHideDuration = 3000,
    } = options;

    setOpen(true);
    setMessage(message);
    setSeverity(severity);
    setAnchorOrigin(anchorOrigin);
    setAutoHideDuration(autoHideDuration);
  };

  // Function to handle closing the Snackbar
  const handleClose = () => {
    setOpen(false);
  };

  // Context value containing functions and properties related to the Snackbar
  const contextValue = {
    showSnackbar,
    snackbarProps: {
      open,
      onClose: handleClose,
      autoHideDuration,
      anchorOrigin,
    },
    snackbarContentProps: { severity },
    snackbarMessage: message,
  };

  // Provide the context value to children components
  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
    </SnackbarContext.Provider>
  );
};

// Export the SnackbarContext and SnackbarProvider
export { SnackbarContext, SnackbarProvider };
