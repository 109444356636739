// actions.js
// Action types for search fetching request, success, and failure
export const FETCH_SEARCH_REQUEST = "FETCH_SEARCH_REQUEST";
export const FETCH_SEARCH_SUCCESS = "FETCH_SEARCH_SUCCESS";
export const FETCH_SEARCH_FAILURE = "FETCH_SEARCH_FAILURE";

// Action creator for search fetching request
export const fetchSearchRequest = (payload) => ({
  type: FETCH_SEARCH_REQUEST,
  payload,
});

// Action creator for search fetching success
export const fetchSearchSuccess = (searchData) => ({
  type: FETCH_SEARCH_SUCCESS,
  payload: searchData,
});

// Action creator for search fetching failure
export const fetchSearchFailure = (error) => ({
  type: FETCH_SEARCH_FAILURE,
  payload: error,
});
