import React, { Fragment, useState } from 'react'
import NotificationsIcon from '@mui/icons-material/Notifications';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const NortificationBanner = ({ buttontext, contenttype, image, title, message, url, entryid }) => {
    const [showNotification, setShowNotification] = useState(true);
    const handleClose = () => {
        setShowNotification(false);
    };

    if (!showNotification) {
        return false; // Return null to prevent rendering if the notification is closed
    }
    if (contenttype === 'video' || contenttype === 'audio') {
        return (
            <Fragment>
                <div class="notification">
                    <div class="notification-image">

                        {image ? <div className="imgWrapper">
                            <LazyLoadImage
                                alt={title}
                                effect="blur"
                                src={image} />
                        </div> : <NotificationsIcon />}
                    </div>
                    <div class="notification-content">
                        {title && <h2 class="notification-heading">{title}</h2>}
                        {message && <p class="notification-message">{message}</p>}
                        {buttontext &&
                            <div className='notification-link' onClick={
                                () => window.open(`${url}`, '_self')
                            }>{buttontext}</div>
                        }
                    </div>
                </div>

            </Fragment >
        )
    } else if (contenttype === 'youtube') {
        return (
            <Fragment>
                <div class="notification">
                    <div class="notification-image">
                        {image ?  <div className="imgWrapper">
                        <LazyLoadImage
                            alt={title}
                            effect="blur"
                            src={image} />
                    </div> : <NotificationsIcon />}
                    </div>
                    <div class="notification-content">
                        {title && <h2 class="notification-heading">{title}</h2>}
                        {message && <p class="notification-message">{message}</p>}
                        {buttontext &&
                            <div className='notification-link' onClick={
                                () => window.open(`${url}`, '_self')
                            }>{buttontext}</div>
                        }
                    </div>
                </div>

            </Fragment >
        )
    } else if (contenttype === 'rss_feed') {
        return (
            <Fragment>
                <div class="notification">
                    <div class="notification-image">
                        {image ?  <div className="imgWrapper">
                        <LazyLoadImage
                            alt={title}
                            effect="blur"
                            src={image} />
                    </div> : <NotificationsIcon />}
                    </div>
                    <div class="notification-content">
                        {title && <h2 class="notification-heading">{title}</h2>}
                        {message && <p class="notification-message">{message}</p>}
                        {buttontext &&
                            <div className='notification-link' onClick={
                                () => window.open(`${url}`, '_self')
                            }>{buttontext}</div>
                        }
                    </div>
                </div>

            </Fragment >
        )
    } else if (contenttype === 'pdf') {
        return (
            <Fragment>
                <div class="notification">
                    <div class="notification-image">
                        {image ?  <div className="imgWrapper">
                        <LazyLoadImage
                            alt={title}
                            effect="blur"
                            src={image} />
                    </div> : <NotificationsIcon />}
                    </div>
                    <div class="notification-content">
                        {title && <h2 class="notification-heading">{title}</h2>}
                        {message && <p class="notification-message">{message}</p>}
                        {buttontext &&
                            <div className='notification-link' onClick={
                                () => window.open(`${url}`, '_blank')
                            }>{buttontext}</div>
                        }
                    </div>
                </div>

            </Fragment >
        )
    } else {
        return (
            <Fragment>
                <div class="notification">
                    <div class="notification-image">
                        {image &&  <div className="imgWrapper">
                        <LazyLoadImage
                            alt={title}
                            effect="blur"
                            src={image} />
                    </div>}
                    </div>
                    <div class="notification-content">
                        {title && <h2 class="notification-heading">{title}</h2>}
                        {message && <p class="notification-message">{message}</p>}
                        {buttontext &&
                            <div className='notification-link' onClick={
                                () => window.open(`${url}`, '_blank')
                            }>{buttontext}</div>
                        }
                    </div>
                </div>

            </Fragment >
        )
    }
}

export default NortificationBanner