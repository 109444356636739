// reducers.js

import {
  FETCH_RECOMMENDED_REQUEST, // Import action type for requesting recommended data
  FETCH_RECOMMENDED_SUCCESS, // Import action type for successful recommended data fetch
  FETCH_RECOMMENDED_FAILURE, // Import action type for recommended data fetch failure
} from "../../actions/recommendedActions"; // Import action types

// Initial state for the recommended data reducer
const initialState = {
  recommendedData: null, // Initial recommended data is null
  isLoading: false, // Initial loading state is false
  isError: null, // Initial error state is null
};

// Reducer function to handle recommended data
const RecommendedReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RECOMMENDED_REQUEST:
      // When requesting recommended data, set isLoading to true and reset isError
      return {
        ...state,
        isLoading: true,
        isError: null,
      };
    case FETCH_RECOMMENDED_SUCCESS:
      // When recommended data fetch is successful, update recommendedData, set isLoading to false, and reset isError
      return {
        ...state,
        recommendedData: action.payload, // Update recommended data with the fetched data
        isLoading: false,
        isError: null,
      };
    case FETCH_RECOMMENDED_FAILURE:
      // When recommended data fetch fails, set isLoading to false and update isError with the error message
      return {
        ...state,
        isLoading: false,
        isError: action.payload, // Update error state with the error message
      };
    default:
      return state;
  }
};

export default RecommendedReducer;
