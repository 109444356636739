// actions.js
// Action types for language change request, success, and failure
export const FETCH_LANGUAGECHANGE_REQUEST = "FETCH_LANGUAGECHANGE_REQUEST";
export const FETCH_LANGUAGECHANGE_SUCCESS = "FETCH_LANGUAGECHANGE_SUCCESS";
export const FETCH_LANGUAGECHANGE_FAILURE = "FETCH_LANGUAGECHANGE_FAILURE";

// Action creator for language change request
export const fetchlanguageChangeRequest = (payload) => ({
  type: FETCH_LANGUAGECHANGE_REQUEST,
  payload,
});

// Action creator for language change success
export const fetchlanguageChangeSuccess = (languageChangeData) => ({
  type: FETCH_LANGUAGECHANGE_SUCCESS,
  payload: languageChangeData,
});

// Action creator for language change failure
export const fetchlanguageChangeFailure = (error) => ({
  type: FETCH_LANGUAGECHANGE_FAILURE,
  payload: error,
});
