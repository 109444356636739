// actions.js

// Action types
export const FETCH_CONTINUEWATCHING_REQUEST = "FETCH_CONTINUEWATCHING_REQUEST";
export const FETCH_CONTINUEWATCHING_SUCCESS = "FETCH_CONTINUEWATCHING_SUCCESS";
export const FETCH_CONTINUEWATCHING_FAILURE = "FETCH_CONTINUEWATCHING_FAILURE";

// Action creators
export const fetchContinueWatchingRequest = (payload) => ({
  type: FETCH_CONTINUEWATCHING_REQUEST,
  payload,
});

export const fetchContinueWatchingSuccess = (continuewatchingView) => ({
  type: FETCH_CONTINUEWATCHING_SUCCESS,
  payload: continuewatchingView,
});

export const fetchContinueWatchingFailure = (error) => ({
  type: FETCH_CONTINUEWATCHING_FAILURE,
  payload: error,
});
