// Importing action types for generating OTP
import {
  GENERATE_OTP_REQUEST,   // Action type for requesting OTP generation
  GENERATE_OTP_SUCCESS,   // Action type for successful OTP generation
  GENERATE_OTP_FAILURE,   // Action type for OTP generation failure
} from "../../actions/generateotpActions";  // Importing action types from the respective action file

// Define the initial state for the reducer
const initialState = {
  generateuserOtp: {},  // Initial value for generateuserOtp is an empty object
  isLoading: false,     // Initial value for isLoading is false
  isError: null,        // Initial value for isError is null
};

// Define the reducer function
const generateOtpReducers = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_OTP_REQUEST:
      // When the GENERATE_OTP_REQUEST action is dispatched
      return {
        ...state,
        isLoading: true,   // Set isLoading to true
        isError: null,     // Reset isError to null
      };
    case GENERATE_OTP_SUCCESS:
      // When the GENERATE_OTP_SUCCESS action is dispatched
      return {
        ...state,
        generateuserOtp: action.payload,  // Update generateuserOtp with the payload from the action
        isLoading: false,                 // Set isLoading to false
        isError: null,                    // Reset isError to null
      };
    case GENERATE_OTP_FAILURE:
      // When the GENERATE_OTP_FAILURE action is dispatched
      return {
        ...state,
        generateuserOtp: null,            // Reset generateuserOtp to null
        isLoading: false,                 // Set isLoading to false
        isError: action.payload,          // Update isError with the payload from the action
      };
    default:
      // If none of the above action types match, return the current state
      return state;
  }
};

// Export the reducer as the default export
export default generateOtpReducers;
