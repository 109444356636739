// Import necessary functions and effects from the redux-saga library
import { takeLatest, put, call } from "redux-saga/effects";

// Import the TVSCHEDULE constant from the constants utility file
import { TVSCHEDULE } from "../../../utility/constants";

// Import the Axios library for making HTTP requests
import axios from "axios";

// Import the FECTH_TVSCHEDULE_REQUEST action type and the fetchTvscheduleSuccess and fetchTvscheduleFailure action creators from the tvscheduleActions file
import {
  FECTH_TVSCHEDULE_REQUEST,
  fetchTvscheduleSuccess,
  fetchTvscheduleFailure,
} from "../../actions/tvscheduleActions";

// Define a generator function fecthTvschedule that takes an action object as an argument
function* fecthTvschedule(action) {
  try {
    // Create a new FormData object to hold the request data
    const episodeFormdata = new FormData();
    // Append the time_zone_country data to the FormData object
    episodeFormdata.append("time_zone_country", action.payload.time_zone_country);

    // Use the call effect to make a POST request to the TVSCHEDULE endpoint with the FormData
    const response = yield call(axios.post, TVSCHEDULE, episodeFormdata, {
      headers: {
        token: localStorage.getItem("token"), // Include the token from localStorage in the request headers
      },
    });

    // Check if the response status is 200 (OK) and the status property of the response data is "Success"
    if (response.status === 200 && response.data.status === "Success") {
      // If the response is successful, dispatch the fetchTvscheduleSuccess action with the response data as payload
      yield put(fetchTvscheduleSuccess(response.data));
    }
  } catch (error) {
    // If an error occurs, dispatch the fetchTvscheduleFailure action with the error message as payload
    yield put(fetchTvscheduleFailure(error.message));
  }
}

// Define a generator function TvscheduleSaga
export function* TvscheduleSaga() {
  // Use the takeLatest effect to listen for the FECTH_TVSCHEDULE_REQUEST action and run the fecthTvschedule generator function when it's dispatched
  yield takeLatest(FECTH_TVSCHEDULE_REQUEST, fecthTvschedule);
}

// Export the TvscheduleSaga as the default export
export default TvscheduleSaga;