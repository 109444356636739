// reducers.js

import {
  FETCH_PROFILE_DETAILS_REQUEST, // Import action type for requesting profile details data
  FETCH_PROFILE_DETAILS_SUCCESS, // Import action type for successful profile details data fetch
  FETCH_PROFILE_DETAILS_FAILURE, // Import action type for profile details data fetch failure
} from "../../actions/profileActions"; // Import action types

// Initial state for the profile details data reducer
const initialState = {
  profileDetails: null, // Initial profile details data is null
  isLoading: false, // Initial loading state is false
  isError: null, // Initial error state is null
};

// Reducer function to handle profile details data
const profileDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROFILE_DETAILS_REQUEST:
      // When requesting profile details data, set isLoading to true and reset isError
      return {
        ...state,
        isLoading: true,
        isError: null,
      };
    case FETCH_PROFILE_DETAILS_SUCCESS:
      // When profile details data fetch is successful, update profileDetails, set isLoading to false, and reset isError
      return {
        ...state,
        profileDetails: action.payload, // Update profile details data with the fetched data
        isLoading: false,
        isError: null,
      };
    case FETCH_PROFILE_DETAILS_FAILURE:
      // When profile details data fetch fails, set isLoading to false and update isError with the error message
      return {
        ...state,
        isLoading: false,
        isError: action.payload, // Update error state with the error message
      };
    default:
      return state;
  }
};

export default profileDetailsReducer;
