// import external modules
import React from "react";
import { Route } from "react-router-dom";

// import internal(own) modules
import ErrorLayout from "../publicLayout";

// Define a functional component called ErrorLayoutRoute which takes in props including render function and rest of the props
const ErrorLayoutRoute = ({ render, ...rest }) => {
  // Return a Route component from react-router-dom, spreading the rest of the props and defining a render prop function
  return (
    <Route
      {...rest} // Spread the rest of the props onto the Route component
      render={(matchProps) => (
        // Render a component ErrorLayout and pass in the result of calling the render function with matchProps
        <ErrorLayout>{render(matchProps)}</ErrorLayout>
      )}
    />
  );
};

// Export the ErrorLayoutRoute component as the default export
export default ErrorLayoutRoute;
