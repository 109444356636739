// Importing action types
import {
  FETCH_EPISODE_REQUEST,
  FETCH_EPISODE_SUCCESS,
  FETCH_EPISODE_FAILURE,
} from "../../actions/episodeActions";

// Define the initial state for the reducer
const initialState = {
  episodeData: null,  // Initial value for episodeData is null
  isLoading: false,   // Initial value for isLoading is false
  isError: null,      // Initial value for isError is null
};

// Define the EpisodeReducer function
const EpisodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EPISODE_REQUEST:
      // When the FETCH_EPISODE_REQUEST action is dispatched
      return {
        ...state,
        isLoading: true,   // Set isLoading to true
        isError: null,     // Reset isError to null
      };
    case FETCH_EPISODE_SUCCESS:
      // When the FETCH_EPISODE_SUCCESS action is dispatched
      return {
        ...state,
        episodeData: action.payload,  // Update episodeData with the payload from the action
        isLoading: false,             // Set isLoading to false
        isError: null,                // Reset isError to null
      };
    case FETCH_EPISODE_FAILURE:
      // When the FETCH_EPISODE_FAILURE action is dispatched
      return {
        ...state,
        isLoading: false,             // Set isLoading to false
        isError: action.payload,      // Update isError with the payload from the action
      };
    default:
      // If none of the above action types match, return the current state
      return state;
  }
};

export default EpisodeReducer;
