// sagas.js
import { takeLatest, put, call } from "redux-saga/effects";
import { SEARCHDATA } from "../../../utility/constants";
import axios from "axios";
import {
  FETCH_SEARCH_DATA_REQUEST,
  fetchSearchDataSuccess,
  fetchSearchDataFailure,
} from "../../actions/fecthSearchDataActions";

function* fecthSearchData(action) {
  try {

    const response = yield call(axios.get, SEARCHDATA, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200 && response.data.status === "Success") {
      yield put(fetchSearchDataSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchSearchDataFailure(error.message));
  }
}

export function* SearchDataSaga() {
  yield takeLatest(FETCH_SEARCH_DATA_REQUEST, fecthSearchData);
}
export default SearchDataSaga;
