import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const CustomModal = ({ isOpen, handleClose, children, fullScreen, modalClass }) => {
  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      // TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      className={`videoPlayerWrapper ${modalClass}`}
    >


      <DialogContent className="playerBody">


        <Button
          className="close_ d-flex align-items-center justify-content-center"
          onClick={handleClose}
        >
          <CloseIcon />
        </Button>


        {isOpen === true && <>{children}</>}
      </DialogContent>
    </Dialog>
  );
};

export default CustomModal;
