// getTokenActions.js
// Action types for top menu fetching request, success, and failure
export const FETCH_TOPMENU_REQUEST = "FETCH_TOPMENU_REQUEST";
export const FETCH_TOPMENU_SUCCESS = "FETCH_TOPMENU_SUCCESS";
export const FETCH_TOPMENU_FAILURE = "FETCH_TOPMENU_FAILURE";

// Action creator for top menu fetching request
export const fetchTopMenuRequest = (payload) => ({
  type: FETCH_TOPMENU_REQUEST,
  payload,
});

// Action creator for top menu fetching success
export const fetchTopMenuSuccess = (topMenuData) => ({
  type: FETCH_TOPMENU_SUCCESS,
  payload: topMenuData,
});

// Action creator for top menu fetching failure
export const fetchTopMenuFailure = (error) => ({
  type: FETCH_TOPMENU_FAILURE,
  payload: error,
});
