// Importing action types
import {
  FETCH_DYNAMICPAGES_REQUEST,
  FETCH_DYNAMICPAGES_SUCCESS,
  FETCH_DYNAMICPAGES_FAILURE,
} from "../../actions/dynamicPagesActions";

// Define the initial state for the reducer
const initialState = {
  dynamicpagesData: null,  // Initial value for dynamicpagesData is null
  isLoading: false,   // Initial value for isLoading is false
  isError: null,      // Initial value for isError is null
};

// Define the DynamicPagesReducer function
const DynamicPagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DYNAMICPAGES_REQUEST:
      // When the FETCH_DYNAMICPAGES_REQUEST action is dispatched
      return {
        ...state,
        isLoading: true,   // Set isLoading to true
        isError: null,     // Reset isError to null
      };
    case FETCH_DYNAMICPAGES_SUCCESS:
      // When the FETCH_DYNAMICPAGES_SUCCESS action is dispatched
      return {
        ...state,
        dynamicpagesData: action.payload,  // Update dynamicpagesData with the payload from the action
        isLoading: false,             // Set isLoading to false
        isError: null,                // Reset isError to null
      };
    case FETCH_DYNAMICPAGES_FAILURE:
      // When the FETCH_DYNAMICPAGES_FAILURE action is dispatched
      return {
        ...state,
        isLoading: false,             // Set isLoading to false
        isError: action.payload,      // Update isError with the payload from the action
      };
    default:
      // If none of the above action types match, return the current state
      return state;
  }
};

export default DynamicPagesReducer;
