// actions.js

// Action types for requesting episode data
export const FETCH_EPISODE_REQUEST = "FETCH_EPISODE_REQUEST";

// Action types for successfully fetching episode data
export const FETCH_EPISODE_SUCCESS = "FETCH_EPISODE_SUCCESS";

// Action types for failure in fetching episode data
export const FETCH_EPISODE_FAILURE = "FETCH_EPISODE_FAILURE";

// Action creator for initiating episode data fetch request
export const fetchEpisodeRequest = (payload) => ({
  type: FETCH_EPISODE_REQUEST,
  payload,
});

// Action creator for successful episode data fetch
export const fetchEpisodeSuccess = (episodeData) => ({
  type: FETCH_EPISODE_SUCCESS,
  payload: episodeData,
});

// Action creator for handling failure in fetching episode data
export const fetchEpisodeFailure = (error) => ({
  type: FETCH_EPISODE_FAILURE,
  payload: error,
});
