// ::// actions.js
// Action types for TV schedule fetching request, success, and failure
export const FECTH_TVSCHEDULE_REQUEST = "FECTH_TVSCHEDULE_REQUEST";
export const FECTH_TVSCHEDULE_SUCCESS = "FECTH_TVSCHEDULE_SUCCESS";
export const FECTH_TVSCHEDULE_FAILURE = "FECTH_TVSCHEDULE_FAILURE";

// Action creator for TV schedule fetching request
export const fetchTvscheduleRequest = (payload) => ({
  type: FECTH_TVSCHEDULE_REQUEST,
  payload,
});

// Action creator for TV schedule fetching success
export const fetchTvscheduleSuccess = (tvscheduleData) => ({
  type: FECTH_TVSCHEDULE_SUCCESS,
  payload: tvscheduleData,
});

// Action creator for TV schedule fetching failure
export const fetchTvscheduleFailure = (error) => ({
  type: FECTH_TVSCHEDULE_FAILURE,
  payload: error,
});
