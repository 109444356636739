import { takeLatest, put, call, delay } from "redux-saga/effects";
import { CONTINUEWATCHING } from "../../../utility/constants";

import {
  DELETE_CONTINUEWATCHING_REQUEST,
  deleteContinueWatchingSuccess,
  deleteContinueWatchingFailure,
} from "../../actions/continueWatchingActions/deleteActions";

function* continueWatchingDelete(action) {
  try {
    const response = yield call(fetch, CONTINUEWATCHING, {
      method: "DELETE",
      headers: {
        token: localStorage.getItem("token"),

      },
      body: action.payload,
    });

    const data = yield response.json();
    // if (data.status === 200 && data.data.status === "Success") {
    if (data.status === "Success") {
      yield put(deleteContinueWatchingSuccess(data));
      yield delay(3000);
      window.location.reload();
      // Additional actions or logic here if needed
    } else {
      yield put(deleteContinueWatchingFailure(data.error));
    }
  } catch (error) {
    yield put(deleteContinueWatchingFailure(error.message));
  }
}

export function* deleteContinueWatchingSaga() {
  yield takeLatest(DELETE_CONTINUEWATCHING_REQUEST, continueWatchingDelete);
}
export default deleteContinueWatchingSaga;
