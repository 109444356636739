// import React, { useContext } from "react";
import React, { useState, useEffect, Fragment, useContext, useRef } from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import GPayIcon from "../../assets/images/g-pay.png";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { SnackbarContext } from "../snackbar/SnackbarContext";
import { ModalContext } from "./modalContext";
import { Login } from "@mui/icons-material";
import { connect } from "react-redux";
import { generateOTPRequest } from "../../Redux/actions/generateotpActions";
//import CountdownTimer from "../countDownTimer";
import { verifyOtpRequest } from "../../Redux/actions/getVerifyOtpActions";
// import { fetchRemoveotherdeviceRequest } from "../../Redux/actions/removeOtherDeviceActions";
import SocialLogin from "./socialLogin";
import CircularProgress from "@mui/material/CircularProgress";
import loginBg from "../../assets/images/modalBg.png";
import Moment from 'react-moment';
import UAParser from 'ua-parser-js';
import { MULTIDEVICELOGOUT, UUID } from "../../utility/constants";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const LoginModal = ({

  generateOtpForUser,
  isVerifyLoading,
  isLoading,
  isError,
  isVerifyError,
  verifyOtpForUser,
  generateOTP,
  verifyUserOtp,
}) => {
  const { isOpen, isClose, modalCustom } = useContext(ModalContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { openModal, setCustomModal } = useContext(ModalContext);
  const [isLoadingShow, setIsLoadingShow] = useState(null);
  const defaultMinutes = 2;
  const defaultSeconds = 30;
  const [minutes, setMinutes] = useState(defaultMinutes);
  const [seconds, setSeconds] = useState(defaultSeconds);
  const [isRunning, setIsRunning] = useState(false);


  const [state, setState] = useState({
    currentOs: '',
    activeDevice: '',
    failureMessage: "",
    deviceList: [],
    isLoginbyMobile: false,
    isMobilenumber: "",
    isEmailaddress: "",
    emailOTP1: "",
    emailOTP2: "",
    emailOTP3: "",
    emailOTP4: "",
  });
  const [hiddenButtons, setHiddenButtons] = useState(Array(state.deviceList.length).fill(false));
  const buttonRefs = useRef([]);
  const parser = new UAParser();
  const getOS = parser.getOS().name;
  const getBrowser = parser.getBrowser().name;
  useEffect(() => {
    if (getOS !== undefined && getBrowser !== undefined) {
      try {
        if (getOS === "Android" || getOS === "iOS") {
          const getDeviceModel = parser.getBrowser().name;
          setState((prevState) => ({
            ...prevState,
            currentOs: getOS, activeDevice: getDeviceModel,
          }));
        }
        else {
          const getBrowser = parser.getBrowser().name;
          setState((prevState) => ({
            ...prevState,
            currentOs: getOS, activeDevice: getBrowser,
          }));
        }
      } catch (error) {

      }

    }

    if (
      generateOtpForUser !== null &&
      generateOtpForUser !== undefined &&
      generateOtpForUser.status === "Success"
    ) {
      // ;
      let message = generateOtpForUser.data;
      showSnackbar(message, {
        severity: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 5000,
      });
      openLoginModal("verifyOtp");
      // isClose();
      // isOpen("verifyOtp"); // if Success open otp modal
      // // setIsTimerRunning(true);
      setIsRunning(true);
    }
    if (
      generateOtpForUser !== null &&
      generateOtpForUser !== undefined &&
      generateOtpForUser.status === "Failed"
    ) {
      // 
      let message = generateOtpForUser.error;
      showSnackbar(message, {
        severity: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 5000,
      });
    }


    if (
      verifyOtpForUser &&
      verifyOtpForUser !== null &&
      verifyOtpForUser !== undefined &&
      verifyOtpForUser.status === "Failed" && verifyOtpForUser?.data !== "Invalid OTP"
    ) {
      const failureList = verifyOtpForUser?.device_info.length > 0 && verifyOtpForUser.device_info;
      const failureMessage = verifyOtpForUser?.data
      // console.log('faliureData', failureList)
      //console.log('failureMessage', failureMessage)
      setState((prevState) => ({
        ...prevState,
        deviceList: failureList, failureMessage: failureMessage,
      }));
      openLoginModal("exceed");
    }
    if (
      verifyOtpForUser &&
      verifyOtpForUser !== null &&
      verifyOtpForUser !== undefined &&
      verifyOtpForUser.status === "Failed" && verifyOtpForUser?.data === "Invalid OTP"
    ) {

      const message = verifyOtpForUser.data;
      showSnackbar(message, {
        severity: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 5000,
      });
    }
    if (
      verifyOtpForUser &&
      verifyOtpForUser !== null &&
      verifyOtpForUser !== undefined &&
      verifyOtpForUser.status === "Success"
    ) {
      const message = verifyOtpForUser.data;
      localStorage.setItem("token", verifyOtpForUser.token);
      isClose();
      showSnackbar(message, {
        severity: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 5000,
      });
    }

  }, [verifyOtpForUser, generateOtpForUser]);

  useEffect(() => {
    if (isOpen === true) {
      document.body.classList.add("overflow-inherit");
    } else {
      document.body.classList.remove("overflow-inherit");
    }
  }, [isOpen]);
  useEffect(() => {
    let timer;

    if (isRunning) {
      timer = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else {
          if (minutes > 0) {
            setMinutes(minutes - 1);
            setSeconds(59);
          } else {
            clearInterval(timer);
            setIsRunning(false);
            setMinutes(defaultMinutes);
            setSeconds(defaultSeconds);
          }
        }
      }, 1000);
    } else {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isRunning, minutes, seconds, defaultMinutes, defaultSeconds]);


  const openLoginModal = (value) => {
    setCustomModal(value); // Set the custom value
    openModal(); // Open the modal
  };

  const getOtpforClient = async (event) => {
    setIsLoadingShow(true);
    const mobileRegex = /^\d{10}$/;
    let emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let deviceInfo = JSON.parse(localStorage.getItem("device"));
    let deviceName = `${deviceInfo.device.type.toLowerCase()}${"_"}${deviceInfo.client.name.toLowerCase()}${"_"}${deviceInfo.os.name.toLowerCase()}${"_"}${deviceInfo.os.version.replace(
      /\.0$/,
      ""
    )}${"_"}${deviceInfo.os.platform.toLowerCase()}`;
    let osName = `${deviceInfo.os.name.toLowerCase()}`;

    let isLoginbyMobile = state.isLoginbyMobile;
    let emailIdvalue = state.isEmailaddress;
    let mobileNumberValue = state.isMobilenumber;
    try {
      if (!isLoginbyMobile) {
        setIsLoadingShow(true);
        if (
          emailIdvalue === undefined ||
          emailIdvalue === null ||
          emailIdvalue === ""
        ) {
          let message = "The email address you entered can't be empty.";
          showSnackbar(message, {
            severity: "warning",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 5000,
          });
          setTimeout(() => {
            setIsLoadingShow(false);
          }, 1000);
          return false;
        } else if (!emailRegex.test(String(emailIdvalue).toLowerCase())) {
          let message = "Please enter a valid email address.";
          showSnackbar(message, {
            severity: "warning",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 5000,
          });
          setTimeout(() => {
            setIsLoadingShow(false);
          }, 1000);
          return false;
        }
      } else {
        //mobile number
        if (
          mobileNumberValue === undefined ||
          mobileNumberValue === null ||
          mobileNumberValue === ""
        ) {
          let message = "The phone number you entered can't be empty.";
          showSnackbar(message, {
            severity: "warning",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 5000,
          });
          setTimeout(() => {
            setIsLoadingShow(false);
          }, 1000);
          return false;
        } else if (!mobileRegex.test(String(mobileNumberValue))) {
          let message = "Please enter a valid mobile number.";
          showSnackbar(message, {
            severity: "warning",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 5000,
          });
          setTimeout(() => {
            setIsLoadingShow(false);
          }, 1000);
          return false;
        }
      }
      setTimeout(() => {
        setIsLoadingShow(false);
      }, 2000);
      // console.log('loginPayloadData', loginPayloadData)
      await generateOTP({ 'isLoginbyMobile': isLoginbyMobile, "email": emailIdvalue, "os": state.currentOs, "name": state.activeDevice, "mobile": `${localStorage.getItem("countryCallingcode") + mobileNumberValue}`, "device": state.activeDevice });
      //generateOtpForUser response here
    } catch (error) { }
  };
  const chagngeGenerateOtpFor = () => {
    setState((prevState) => ({
      ...prevState,
      isLoginbyMobile: !state.isLoginbyMobile,
    }));
  };
  const handelChangegenerateOtp = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkOTP = async (e) => {
    if (e.target.value !== "") {
      setState((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
      let input = e.target.getAttribute("id");
      if (input !== "emailOTP4") {
        e.target.nextSibling.focus();
      }
    }
  };

  const isOTPEmpty = (e) => { };
  const handelOtpSubmit = (e) => {
    setIsLoadingShow(true);
    let deviceInfo = JSON.parse(localStorage.getItem("device"));
    let deviceName = `${deviceInfo.device.type.toLowerCase()}${"_"}${deviceInfo.client.name.toLowerCase()}${"_"}${deviceInfo.os.name.toLowerCase()}${"_"}${deviceInfo.os.version.replace(
      /\.0$/,
      ""
    )}${"_"}${deviceInfo.os.platform.toLowerCase()}`;
    let osName = `${deviceInfo.os.name.toLowerCase()}`;

    e.preventDefault();
    const { emailOTP1, emailOTP2, emailOTP3, emailOTP4 } = state;

    if (emailOTP1 && emailOTP2 && emailOTP3 && emailOTP4) {
      let isLoginbyMobile = state.isLoginbyMobile;
      let emailIdvalue = state.isEmailaddress;
      let mobileNumberValue = state.isMobilenumber;
      const payloadverifyOtp = new FormData();

      if (!isLoginbyMobile) {
        payloadverifyOtp.append("email", emailIdvalue);
        localStorage.setItem("email", emailIdvalue);
        payloadverifyOtp.append("account_type", "2");
      } else {
        payloadverifyOtp.append(
          "mobile",
          `${localStorage.getItem("countryCallingcode") + mobileNumberValue}`
        );
        localStorage.setItem("mobile", `${mobileNumberValue}`);
        payloadverifyOtp.append("account_type", "1");
      }
      payloadverifyOtp.append(
        "otp",
        `${emailOTP1}${emailOTP2}${emailOTP3}${emailOTP4}`
      );


      setTimeout(() => {
        setIsLoadingShow(false);
      }, 800);
      let main = `${emailOTP1}${emailOTP2}${emailOTP3}${emailOTP4}`
      // console.log("main", main.length)
      let activedevice = state.activeDevice + "(" + state.currentOs + ")"
      localStorage.setItem('activedevice', activedevice)
      verifyUserOtp({ 'isLoginbyMobile': isLoginbyMobile, "os": state.currentOs, "name": state.activeDevice, 'otp': `${emailOTP1}${emailOTP2}${emailOTP3}${emailOTP4}`, "email": state.isEmailaddress, "mobile": state.isMobilenumber });
    } else {
      const message = "Please enter the OTP.";
      showSnackbar(message, {
        severity: "warning",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 5000,
      });
    }
  };

  // Function to edit user details
  const editDetails = () => {
    openLoginModal("login"); // Open the login modal
  };

  // Function to resend OTP
  const resendOtp = async () => {
    if (!isRunning) {
      setMinutes(defaultMinutes); // Reset minutes to default
      setSeconds(defaultSeconds); // Reset seconds to default
      await getOtpforClient(); // Get OTP for the client
    }
    setIsRunning((prevState) => !prevState); // Toggle the isRunning state
  };


  const closeDevicePopup = () => {

  }

  const handleLogoutClick = (index, device_uuid) => {
    const originalArray = state.activeDevice
    const filteredArray = [];
    // 
    for (let i = 0; i < originalArray.length; i++) {
      if (originalArray[i].device_uuid !== device_uuid) {
        filteredArray.push(originalArray[i]);
      }
    }
    // console.log('filteredArray', filteredArray);

    setHiddenButtons(prevHiddenButtons => {
      const updatedHiddenButtons = [...prevHiddenButtons];
      updatedHiddenButtons[index] = true;
      return updatedHiddenButtons;
    });



    let formData = new FormData();
    formData.append("uuid", device_uuid);
    axios
      .post(MULTIDEVICELOGOUT, formData, {
        headers: {
          token: localStorage.getItem("token"),

        },
      })
      .then((response) => {
        // 
        if (response.data.status === "success") {
          let message = response.data.msg
          showSnackbar(message, {
            severity: "warning",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 5000,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <>
      {isOpen === true && modalCustom !== null && modalCustom !== undefined ? (
        <>


          {modalCustom === "login" ? (
            <BootstrapDialog
              aria-labelledby="customized-dialog-title"
              open={isOpen}
              className="loginModal"
              TransitionComponent={Transition}
            >
              <DialogContent dividers className="modal-body">
                <Button
                  className="close_ d-flex align-items-center justify-content-center"
                  onClick={isClose}
                >
                  <CloseIcon />
                </Button>
                <Grid
                  item
                  xs={12}
                  className="pt-0 w-100"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={loginBg} alt="img" className="w-100" />
                </Grid>





                <form onSubmit={getOtpforClient} className="position-relative">




                  <p className="txt-light text-center mt-4 mb-4">
                    Enter your{" "}
                    {state.isLoginbyMobile ? " Phone Number" : " Email Address"}{" "}
                    to Login/Sign up
                  </p>
                  {state.isLoginbyMobile ? <p className="caution text-center mt-4 mb-4">{
                    state.isLoginbyMobile
                      ? "Kindly Note: Only Indian mobile numbers are accepted for SMS verification. Users outside India, please use email to receive OTP."
                      : ""}</p> : ""}
                  <Grid item xs={12} className="w-100 form-group mb-3">
                    {state.isLoginbyMobile ? (
                      <TextField
                        id="isMobilenumber"
                        name="isMobilenumber"
                        label="Phone Number"
                        type="tel"
                        variant="outlined"
                        onChange={handelChangegenerateOtp}
                        value={state.isMobilenumber}
                        fullWidth
                        inputProps={{
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />
                    ) : (
                      <TextField
                        id="isEmailaddress"
                        name="isEmailaddress"
                        label="Email Address"
                        type="email"
                        variant="outlined"
                        onChange={handelChangegenerateOtp}
                        value={state.isEmailaddress}
                        fullWidth
                        inputProps={{
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    className="w-100 form-group pt-2 mb-3 text-center"
                  >
                    <span
                      className="text-sky cursor border-effect"
                      onClick={chagngeGenerateOtpFor}
                    >
                      {state.isLoginbyMobile
                        ? "Login Via Email Address"
                        : "Login Via Phone Number"}
                    </span>
                  </Grid>

                  <Button
                    variant="contained"
                    className="w-100 text-capitalize bg-red btn-lg d-flex align-items-center"
                    onClick={resendOtp}
                  >
                    {isLoadingShow === true ? (
                      <CircularProgress className="mx-2 spinerLoader" />
                    ) : (
                      "Next"
                    )}
                  </Button>

                  {/* <Grid
                    item
                    xs={12}
                    className="w-100 form-group pt-3 mb-2 text-center"
                    sx={{ opacity: "0.8" }}
                  >
                    <span className="text-white">Or</span>
                  </Grid> */}

                  {/* <Grid
                    item
                    xs={12}
                    className="w-100 form-group pt-1 mb-3 text-center"
                    sx={{ opacity: "0.8" }}
                  >
                    <span className="txt-light">
                      Continue With Social Media Account
                    </span>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    className="w-100 form-group pt-1 mb-3 text-center d-flex align-items-center justify-content-center loginWith"
                  >
                    <Link
                      to=""
                      className="d-flex align-items-center justify-content-center"
                    >
                      <img src={GPayIcon} alt="img" />
                    </Link>
                    <Link
                      to=""
                      className="mx-3 d-flex align-items-center justify-content-center"
                    >
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </Link>
                  </Grid> */}
                  {/* <SocialLogin /> */}

                  <Grid
                    item
                    xs={12}
                    className="w-100 form-group pt-1 d-flex align-items-center justify-content-center bottomPart mt-3"
                  >
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label="By clicking  Login/Sign up, you agree to our Terms, Privacy Policy and Terms & Conditions"
                    />
                  </Grid>
                </form>
              </DialogContent>
            </BootstrapDialog>
          ) : (
            ""
          )}

        </>
      ) : (
        ""
      )}

      {modalCustom === "verifyOtp" ? (
        <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={isOpen}
          className="loginModal"
          TransitionComponent={Transition}
        >
          <DialogContent dividers className="modal-body">
            <Button
              className="close_ d-flex align-items-center justify-content-center"
              onClick={isClose}
            >
              <CloseIcon />
            </Button>

            <Grid
              item
              xs={12}
              className="pt-0 w-100 mb-5"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={loginBg} alt="img" className="w-100" />
            </Grid>

            <h3 className="txt-light2 text-center font-w-300 mt-0">
              Verify Details
            </h3>
            <p className="txt-light text-center">
              We have sent an OTP to your
              <br />
              {state.isLoginbyMobile ? "Phone Number" : " Email Address"}
            </p>

            <form onSubmit={handelOtpSubmit} className="py-2">
              <div className="my-0">
                <p className="cursor txt-light d-flex align-items-center w-75 m-auto font-w-300">
                  {state.isLoginbyMobile
                    ? `${"+"}${localStorage.getItem(
                      "countryCallingcode"
                    )}${" "}${state.isMobilenumber}`
                    : state.isEmailaddress}
                  <span
                    className="border-effect cursor ml-auto"
                    onClick={editDetails}
                  >
                    Edit
                    {/* Edit {state.isLoginbyMobile ? " Mobile" : " Email"} */}
                  </span>
                </p>
              </div>
              <div className="d-flex mt-3 mb-3  align-items-center justify-content-between mt-3 mb-4 otp_box position-relative w-75 m-auto">
                <input
                  vlaue={state.emailOTP1}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  type="text"
                  className="mr-3 form-control"
                  id="emailOTP1"
                  name="emailOTP1"
                  onChange={checkOTP}
                  onBlur={isOTPEmpty}
                  maxLength="1"
                />
                <input
                  vlaue={state.emailOTP2}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  type="text"
                  className="mr-3 form-control"
                  id="emailOTP2"
                  name="emailOTP2"
                  onChange={checkOTP}
                  onBlur={isOTPEmpty}
                  maxLength="1"
                />
                <input
                  vlaue={state.emailOTP3}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  type="text"
                  className="mr-3 form-control"
                  id="emailOTP3"
                  name="emailOTP3"
                  onChange={checkOTP}
                  onBlur={isOTPEmpty}
                  maxLength="1"
                />
                <input
                  vlaue={state.emailOTP4}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  type="text"
                  className="mr-3 form-control"
                  id="emailOTP4"
                  name="emailOTP4"
                  onChange={checkOTP}
                  onBlur={isOTPEmpty}
                  maxLength="1"
                />
              </div>

              <div className="my-3">
                <p className="txt-light mt-3 mb-3 text-center align-items-center w-100 m-auto font-w-300">
                  {isRunning ? (
                    <>Resending OTP in {`( ${`${minutes}m ${seconds}s`} )`}</>
                  ) : (
                    <>
                      {"Haven’t received the OTP? "}
                      <span
                        onClick={getOtpforClient}
                        className="border-effect cursor"
                      >
                        Resend
                      </span>
                    </>
                  )}
                  <div></div>

                </p>

                <Button
                  onClick={handelOtpSubmit}
                  variant="contained"
                  className="w-100 text-capitalize bg-red btn-lg mt-3"
                >
                  {isLoadingShow === true ? (
                    <CircularProgress className="mx-2 spinerLoader" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </form>
          </DialogContent>
        </BootstrapDialog>
      ) : (
        ""
      )}

      {modalCustom === "exceed" ? (<>
        <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={isOpen}
          className="loginModal"
          TransitionComponent={Transition}
        >
          <DialogContent dividers className="modal-body">
            <Button
              className="close_ d-flex align-items-center justify-content-center"
              onClick={isClose}
            >
              <CloseIcon />
            </Button>
            <Grid
              item
              xs={12}
              className="pt-0 w-100"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={loginBg} alt="img" className="w-100" />
            </Grid>
            <Grid
              item
              xs={12}
              className="pt-0 w-100"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="connectedDevicesPopover p-0">
                <div className="shadow">
                  {/* <Button onClick={closeDevicePopup} className="close_"><CloseIcon /></Button> */}
                  <div className="head">
                    <h4>{getOS + " " + getBrowser}</h4>
                    {/* <p>Your device limit has been exceeded. To continue logout of any one of the devices below.</p> */}
                    <p>{state.failureMessage}</p>
                  </div>
                  <div className="head pt-3 pb-3">
                    <h3>Manage Devices</h3>
                    <p>Connected Devices</p>
                  </div>
                  <div className="scroll">
                    {state.deviceList && state.deviceList.length > 0 && state.deviceList.map((item, i) => {
                      buttonRefs.current[i] = React.createRef();

                      return (
                        <div className={`data d-flex align-items-center w-100 ${hiddenButtons[i] ? 'd-none' : ''}`} id={i} key={i}>
                          <div className="w-75">
                            <h3>{item.device_name}</h3>
                            <p>
                              <Moment format="DD-MM-YYYY hh:mm A">
                                {(JSON.stringify(item.last_login)).replace(/"/g, "")}
                              </Moment>
                            </p>
                          </div>

                          <div className="w-25">
                            <Button
                              id={i}
                              className={`btn-border ${hiddenButtons[i] ? 'd-none' : ''}`}
                              onClick={() => handleLogoutClick(i, item.device_uuid)}
                              ref={buttonRefs.current[i]}
                            >
                              Logout
                            </Button>
                          </div>
                        </div>
                      );
                    })}
                    {/* <div className="data d-flex align-items-center w-100" id={`1`}>
                      <div className="w-75">
                        <h3>Name</h3>
                        <p> This is text </p>
                      </div>

                      <div className="w-25">
                        <Button id={1} className="btn-border">Logout</Button>
                      </div>
                    </div>
                    <div className="data d-flex align-items-center w-100" id={`1`}>
                      <div className="w-75">
                        <h3>Name</h3>
                        <p> This is text </p>
                      </div>

                      <div className="w-25">
                        <Button id={1} className="btn-border">Logout</Button>
                      </div>
                    </div> */}
                  </div>
                </div>
                <h4 className="text-y font-16 my-2">{`* The application support devices at a time.`}</h4>
              </div>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </>) : (
        ""
      )}
    </>
  );
};

// export default LoginModal;
const mapStateToProps = (state) => ({
  generateOtpForUser: state.generateOtp.generateuserOtp,
  isLoading: state.generateOtp.isLoading,
  isError: state.generateOtp.isError,
  verifyOtpForUser: state.getVerifyOtp.verifyOTPdata,
  removeDevice: state.removeOtherDevice,
  isVerifyError: state.getVerifyOtp.isError,
  isVerifyLoading: state.getVerifyOtp.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  generateOTP: (payload) => dispatch(generateOTPRequest(payload)),
  verifyUserOtp: (payloadverifyOtp) =>
    dispatch(verifyOtpRequest(payloadverifyOtp)),
  // verifyOtpRequest,
  // removeOtherDeviceApi: fetchRemoveotherdeviceRequest,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
