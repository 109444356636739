// Import necessary functions and effects from the redux-saga library
import { takeLatest, put, call } from "redux-saga/effects";

// Import the STATICPAGE constant from the utility/constants file
import { STATICPAGE } from "../../../utility/constants";

// Import the Axios library for making HTTP requests
import axios from "axios";

// Import the FETCH_STATICPAGE_REQUEST action type and the fetchStaticPageSuccess and fetchStaticPageFailure action creators from the stacticPageActions file
import {
  FETCH_STATICPAGE_REQUEST,
  fetchStaticPageSuccess,
  fetchStaticPageFailure,
} from "../../actions/stacticPageActions";

// Define a generator function fecthStaticPage that takes an action object as an argument
function* fecthStaticPage(action) {
  try {
    // Use the call effect to make a POST request to the STATICPAGE endpoint with the action payload
    const response = yield call(axios.post, STATICPAGE, action.payload, {
      headers: {
        token: localStorage.getItem("token"), // Include the token from localStorage in the request headers
      },
    });

    // Check if the response status is 200 (OK) and the status property of the response data is "Success"
    if (response.status === 200 && response.data.status === "Success") {
      // If the response is successful, dispatch the fetchStaticPageSuccess action with the response data as payload
      yield put(fetchStaticPageSuccess(response.data));
    }
  } catch (error) {
    // If an error occurs, dispatch the fetchStaticPageFailure action with the error message as payload
    yield put(fetchStaticPageFailure(error.message));
  }
}

// Define a generator function StaticPageSaga
export function* StaticPageSaga() {
  // Use the takeLatest effect to listen for the FETCH_STATICPAGE_REQUEST action and run the fecthStaticPage generator function when it's dispatched
  yield takeLatest(FETCH_STATICPAGE_REQUEST, fecthStaticPage);
}

// Export the StaticPageSaga as the default export
export default StaticPageSaga;