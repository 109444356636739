// sagas.js
import { takeLatest, put, call } from "redux-saga/effects"; // Import necessary functions from Redux Saga
import { SEARCH } from "../../../utility/constants"; // Import constant for API endpoint
import axios from "axios"; // Import Axios for making HTTP requests
import {
  // Import action types and action creators for handling search
  FETCH_SEARCH_REQUEST,
  fetchSearchSuccess,
  fetchSearchFailure,
} from "../../actions/searchActions"; // Import action creators for success and failure cases

function* fecthSearchData(action) {
  try {
    // Create a new FormData object for search payload
    var searchPayload = new FormData();

    // Check if the value in the payload is true or not
    if (action.payload.value === true) {
      // If the value is true, append tag to the FormData
      searchPayload.append("tag", action.payload.tag);
    } else {
      // If the value is not true, append category_id to the FormData
      searchPayload.append("category_id", action.payload.category_id);
    }

    // Make POST request to the SEARCH endpoint with searchPayload
    const response = yield call(axios.post, SEARCH, searchPayload, {
      // Include token from local storage in the request headers
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    // Check if the response is successful and status is "Success"
    if (response.status === 200 && response.data.status === "Success") {
      // Dispatch action to handle successful fetch with response data
      yield put(fetchSearchSuccess(response.data));
    }
  } catch (error) {
    // Dispatch action to handle fetch failure with error message
    yield put(fetchSearchFailure(error.message));
  }
}

// Saga watcher function to listen for FETCH_SEARCH_REQUEST action
export function* SearchSaga() {
  yield takeLatest(FETCH_SEARCH_REQUEST, fecthSearchData);
}

// Export the saga watcher function as default
export default SearchSaga;
