// sagas.js
import { takeLatest, put, call } from "redux-saga/effects";
import { HOMECAROUSEL } from "../../../utility/constants";
import axios from "axios";
import {
  FETCH_HOMECAROUSEL_REQUEST,
  fecthHomeCarouselSuccess,
  fecthHomeCarouselFailure,
} from "../../actions/homeCarouselActions";

function* fecthHomeCarousel(action) {
  try {
    const homeCarouselData = new FormData();
    homeCarouselData.append("img_type", "w");
    homeCarouselData.append("category_id", action.payload.categoryid);
    homeCarouselData.append("page", action.payload.page);
    const response = yield call(axios.post, HOMECAROUSEL, homeCarouselData, {
      headers: {
        token: localStorage.getItem("token"),

      },
    });

    if (response.status === 200 && response.data.status === "Success") {
      yield put(fecthHomeCarouselSuccess(response.data));
    }
  } catch (error) {
    yield put(fecthHomeCarouselFailure(error.message));
  }
}

export function* homeCarouselSaga() {
  yield takeLatest(FETCH_HOMECAROUSEL_REQUEST, fecthHomeCarousel);
}
export default homeCarouselSaga;
