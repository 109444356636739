// actions.js
export const DELETE_CONTINUEWATCHING_REQUEST =
  "DELETE_CONTINUEWATCHING_REQUEST";
export const DELETE_CONTINUEWATCHING_SUCCESS =
  "DELETE_CONTINUEWATCHING_SUCCESS";
export const DELETE_CONTINUEWATCHING_FAILURE =
  "DELETE_CONTINUEWATCHING_FAILURE";

export const deleteContinueWatchingRequest = (payload) => ({
  type: DELETE_CONTINUEWATCHING_REQUEST,
  payload,
});

export const deleteContinueWatchingSuccess = (continuewatchingDelete) => ({
  type: DELETE_CONTINUEWATCHING_SUCCESS,
  payload: continuewatchingDelete,
});

export const deleteContinueWatchingFailure = (error) => ({
  type: DELETE_CONTINUEWATCHING_FAILURE,
  payload: error,
});
