import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import VerifiedIcon from "@mui/icons-material/Verified";
import { connect } from "react-redux";
import { fetchTopMenuRequest } from "../../../../Redux/actions/topMenuActions";
import { fetchSideMenuRequest } from "../../../../Redux/actions/sidemenuActions";
import { fetchTokenRequest } from "../../../../Redux/actions/getTokenActions";
import { fetchCommonDataRequest } from "../../../../Redux/actions/commonActions";
import { fetchIPRequest } from "../../../../Redux/actions/ipapiActions";
import MenuIcon from "@mui/icons-material/Menu";
import Skeleton from "@mui/material/Skeleton";
import CustomModal from "../../../../components/modal/modalCustom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';

import {
  ModalProvider,
  ModalContext,
} from "../../../../components/modal/modalContext";
import LoginModal from "../../../../components/modal/loginModal";
import Logout from "./logout";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import ClickAwayListener from '@mui/base/ClickAwayListener';
import { ClickAwayListener } from "@mui/base";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DONATIONURL } from "../../../../utility/constants";
import UAParser from 'ua-parser-js';
import { MyContext } from "../../publicLayout";

const Header = ({ isMedium, isError, menu, fetchTopMenu, fetchTokenData, tokenData, fetchSideMenu, sideMenu, fetchCommonData, fetchIPData, commonData, userCurrentLocation, }) => {
  const history = useHistory();

  const { openModal, setCustomModal } = useContext(ModalContext);
  const storedToken = localStorage.getItem('token');
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const headerRef = useRef();
  const [isMacOS, setIsMacOS] = useState(false);
  const [isUrlModal, setIsUrlModal] = useState(false);
  const [isUrl, setIsUrl] = useState(null);
  const [dropdown, setDropdown] = useState(false);
  const [isOpenBackdrop, setIsOpenBackdrop] = React.useState(false);
  const [isOpenNav, setisOpenNav] = React.useState(false);
  const [windowWidth, setwindowWidth] = useState(windowSize.current[0]);
  const [dropdownTabIndex, setDropdownTabIndex] = useState("");
  const [isOpenSearch, setIsOpenSearch] = useState("");
  const [accessToken, setAccessToken] = useState('');
  const open = Boolean(dropdown);
  const isAuth =
    localStorage.getItem("isConnected") === "false" ||
      localStorage.getItem("isConnected") === null ||
      localStorage.getItem("isConnected") === undefined ||
      localStorage.getItem("isConnected") === ""
      ? false
      : true;
  const [state, setState] = useState({
    commondataItems: {},
    sideMenuItems: [],
    topMenu: [],
    keyword: "",
    focusOnSearch: false,
    isLogin: false,
    isSearchPopUp: false,
  });
  const { commondataItems } = state

  const context = useContext(MyContext);
  useEffect(() => {
    const parser = new UAParser();
    const os = parser.getOS();
    // console.log('os', os)
    setIsMacOS(os.name === 'Mac OS');
    localStorage.setItem('isMac', os.name === 'Mac OS')
  }, []);
  useEffect(() => {
    context.setHeaderEle(headerRef.current);
    // Function to handle API calls related to header
    async function headerApiCall() {
      // Check if token is stored
      if (storedToken) {
        try {
          // If token is available, fetch common data and top menu after a delay
          await Promise.all([fetchTopMenu()]);
        } catch (error) {
          // Log any errors that occur during API calls
          console.error(error);
        }
      } else {
        // If token is not available, fetch token data
        await fetchTokenData();
        // Check token data for validity
        if (tokenData) {
          try {
            // If token fetch is successful
            if (tokenData.status === "Success") {
              // Extract token from response
              const responseToken = tokenData.token;
              // Store the new token in local storage
              localStorage.setItem('token', responseToken);
              localStorage.setItem("isConnected", false);
              // Set the access token in state
              setAccessToken(responseToken);
              // Make subsequent API calls after getting the token
              if (!isMedium) {
                await Promise.all([fetchTopMenu(), fetchCommonData(), fetchIPData()]);
              }
            } else if (tokenData.status === "Failed") {
              // If token fetch fails, throw an error
              throw new Error('Failed to fetch token');
            }
          } catch (error) {
            // Log any errors that occur during token processing
            console.error(error);
          }
        }
      }
    }
    function HandleResize() {
      setwindowWidth(window.innerWidth);
    }
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const headerNavbar = headerRef.current;
      const sticky = headerNavbar.offsetTop;
      if (scrollPosition > sticky) {
        headerNavbar.classList.add("sticky");
      } else {
        headerNavbar.classList.remove("sticky");
      }
    };
    window.addEventListener("resize", HandleResize);
    window.addEventListener("scroll", handleScroll);
    // Call header API function if medium is not set
    if (!isMedium) {
      headerApiCall();
    }
    return () => {
      // clearTimeout(timeout);
      window.removeEventListener("resize", HandleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMedium, storedToken, tokenData]);

  useEffect(() => {
    // Check if userCurrentLocation is defined and not null or undefined
    if (userCurrentLocation ?? false) {
      // Extract country information from userCurrentLocation or use default values
      const countryCoode = userCurrentLocation?.country || "IN";
      const countryName = userCurrentLocation?.country_name || "India";
      const countryCallingcode = (userCurrentLocation?.country_calling_code || "+91").substring(1);

      // Store country information in localStorage
      localStorage.setItem("countryCode", countryCoode);
      localStorage.setItem("countryName", countryName);
      localStorage.setItem("countryCallingcode", countryCallingcode);
    }

    // Check if commonData is defined and not null or undefined
    if (commonData ?? false) {
      try {
        // Check if commonData status is "Success"
        if (commonData.status === "Success") {
          // Extract templateId from commonData and store it in localStorage
          const templateId = commonData.data.template_id;
          const websiteurl = commonData.data.live_store_url.website;
          const matamourl = commonData.data.matomo.matomo_url;
          const siteurl = commonData.data.matomo.site_url;
          const siteid = commonData.data.matomo.site_id;
          sessionStorage.setItem('matamourl', matamourl)
          sessionStorage.setItem('siteurl', siteurl)
          sessionStorage.setItem('siteid', siteid)
          localStorage.setItem("templateid", templateId);
          localStorage.setItem("websiteurl", websiteurl);
          // localStorage.setItem("websiteurl", 'http://localhost:3000');
          setState((prevState) => ({
            ...prevState,
            commondataItems: commonData.data,
          }));
        }
      } catch (error) { }
    }

    // Check if menu is defined and not null or undefined
    if (menu ?? false) {
      try {
        // Check if menu status is "Success"
        if (menu.status === "Success") {
          // Store menu information in localStorage
          localStorage.setItem("logo", menu.logo_url);
          localStorage.setItem("homevalue", menu.menu[0].cat_id);
          localStorage.setItem("autoplay", menu.autoplay);
          // Update state with topMenu data
          setState((prevState) => ({
            ...prevState,
            topMenu: menu.menu,
          }));
        }
      } catch (error) { }
    }

    // Check if sideMenu is defined and not null or undefined
    if (sideMenu ?? false) {
      try {
        // Check if sideMenu status is "Success"
        if (sideMenu.status === "Success") {
          // Update state with sideMenuItems data
          setState((prevState) => ({
            ...prevState,
            sideMenuItems: sideMenu.leftMenu,
          }));
        }
      } catch (error) { }
    }
  }, [userCurrentLocation, commonData, menu, sideMenu]);

  const performHomePostRequest = async (token) => {

    // console.log('isMedium', isMedium)

    setTimeout(async (token) => {
      try {
        if (isMedium === false) {
          await fetchCommonData(token); //common api
          await fetchTopMenu(token);
          // await fetchSideMenu(token);
        } else {

        }


      } catch (error) {
        // Handle errors if any
        console.error('Error occurred:', error);
      }
    }, 800); // 600 milliseconds 
  };
  const handleClick = (event) => {
    setDropdown(!dropdown);
    setIsOpenBackdrop(true);
    document.body.classList.add("overflow-inherit");
  };

  const handleClose = (index) => {
    setDropdown(false);
    //setIsOpenBackdrop(false);
    if (index !== "" && index !== null && index !== undefined) {
      setDropdownTabIndex(index);
    }
    document.body.classList.remove("overflow-inherit");
  };
  const handleCloseModal = () => { // Function for handling modal close
    setIsUrlModal(false);
    // context.setIsPopup(false);
    document.querySelector('html').classList.remove('overflow-hidden');
  };





  const openLoginModal = (value) => {
    document.body.classList.add("overflow-inherit");
    setCustomModal(value); // Set the custom value
    openModal(); // Open the modal
  };


  const redirectTodonation = (value) => {
    // debugger
    setIsUrl(value)
    setIsUrlModal(true);

  };
  const SearchPopup = () => {
    setState((prevState) => ({
      ...prevState,
      isSearchPopUp: !state.isSearchPopUp,
    }));
  };

  if (isError) {
    localStorage.clear()
    window.location.reload()
  }

  return (
    <>
      {isUrlModal && <CustomModal fullScreen={true} isOpen={isUrlModal} handleClose={handleCloseModal}>

        <iframe id="pdf-iframe" src={isUrl + '#toolbar=1&navpanes=1&scrollbar=0'} allowfullscreen scrolling="auto"></iframe>
      </CustomModal>}
      <header className={`d-flex`} id="header" ref={headerRef}>
        <Grid container spacing={6} sx={{ m: "0", alignItems: "center" }}>
          <Grid item sm={3} className="pt-0 res-padding0 item1 part1">
            <Link to="/">
              {localStorage.getItem("logo") ? (
                <img
                  src={`${localStorage.getItem("logo") ? localStorage.getItem("logo") : menu.logo_url}/width=60/height=80/quality=60`}
                  alt="Logo"
                  className="logo"
                />
              ) : (
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={120}
                  height={40}
                  style={{ borderRadius: "50px" }}
                ></Skeleton>
              )}

              <span className="logoTxt res-hide">Shalom Television</span>

            </Link>
          </Grid>

          <Grid
            item
            xs={9}
            className="pt-0 d-flex align-items-center p-0 item3"
          >
            <div className="ml-auto d-flex align-items-center w-100 justify-content-end">
              {isOpenNav === true && (
                <div
                  className="navOverlay"
                  onClick={() => {
                    setisOpenNav(false)
                    setDropdown(false)
                  }
                  }
                ></div>
              )}


              <ul className="list list-inline mb-0 header_Tabs ipadMenu">
                {state.topMenu?.length > 0 &&
                  state.topMenu.map((item, i) => (
                    <>

                      {
                        item.view_type === 'radio' ? <li
                          className="list-inline-item"
                          key={i}
                          onClick={() => {
                            setIsOpenBackdrop(false);
                            setisOpenNav(false);
                          }}
                        >
                          <Link
                            to={`/radio/${item.cat_id}`}
                            data-text={item.cat_name}
                          >
                            {item.cat_name}
                          </Link>
                        </li> : item.view_type === 'tvschedule' ? <li
                          className="list-inline-item"
                          key={i}
                          onClick={() => {
                            setIsOpenBackdrop(false);
                            setisOpenNav(false);
                          }}
                        >
                          <Link
                            to={`/tvschedule/${item.cat_id}`}
                            data-text={item.cat_name}
                          >
                            {item.cat_name}
                          </Link>
                        </li> : item.view_type === 'prayer' ? <li
                          className="list-inline-item"
                          key={i}
                          onClick={() => {
                            setIsOpenBackdrop(false);
                            setisOpenNav(false);
                          }}
                        >
                          <Link
                            to={`/prayer/${item.cat_id}`}
                            data-text={item.cat_name}
                          >
                            {item.cat_name}
                          </Link>
                        </li> : <li
                          className="list-inline-item"
                          key={i}
                          onClick={() => {
                            setIsOpenBackdrop(false);
                            setisOpenNav(false);
                          }}
                        >
                          <Link
                            to={`/browse/${item.cat_name}/${item.cat_id}`}
                            data-text={item.cat_name}
                          >
                            {item.cat_name}
                          </Link>
                        </li>
                      }
                      {/* {

                        item.view_type !== 'radio' ? <li
                          className="list-inline-item"
                          key={i}
                          onClick={() => {
                            setIsOpenBackdrop(false);
                            setisOpenNav(false);
                          }}
                        >
                          <Link
                            to={`/browse/${item.cat_name}/${item.cat_id}`}
                            data-text={item.cat_name}
                          >
                            {item.cat_name}
                          </Link>
                        </li> : <li
                          className="list-inline-item"
                          key={i}
                          onClick={() => {
                            setIsOpenBackdrop(false);
                            setisOpenNav(false);
                          }}
                        >
                          <Link
                            to={`/radio/${item.cat_id}`}
                            data-text={item.cat_name}
                          >
                            {item.cat_name}
                          </Link>
                        </li>

                      } */}
                    </>
                  ))}
              </ul>


              <div className="res_nav__  res-hide">
                <ul className="list list-inline mb-0 header_Tabs">
                  {state.topMenu?.length > 0 &&
                    state.topMenu.map((item, i) => (
                      <>

                        {
                          item.view_type === 'radio' ? <li
                            className="list-inline-item"
                            key={i}
                            onClick={() => {
                              setIsOpenBackdrop(false);
                              setisOpenNav(false);
                            }}
                          >
                            <Link
                              to={`/radio/${item.cat_id}`}
                              data-text={item.cat_name}
                            >
                              {item.cat_name}
                            </Link>
                          </li> : item.view_type === 'tvschedule' ? <li
                            className="list-inline-item"
                            key={i}
                            onClick={() => {
                              setIsOpenBackdrop(false);
                              setisOpenNav(false);
                            }}
                          >
                            <Link
                              to={`/tvschedule/${item.cat_id}`}
                              data-text={item.cat_name}
                            >
                              {item.cat_name}
                            </Link>
                          </li> : item.view_type === 'prayer' ? <li
                            className="list-inline-item"
                            key={i}
                            onClick={() => {
                              setIsOpenBackdrop(false);
                              setisOpenNav(false);
                            }}
                          >
                            <Link
                              to={`/prayer/${item.cat_id}`}
                              data-text={item.cat_name}
                            >
                              {item.cat_name}
                            </Link>
                          </li> : <li
                            className="list-inline-item"
                            key={i}
                            onClick={() => {
                              setIsOpenBackdrop(false);
                              setisOpenNav(false);
                            }}
                          >
                            <Link
                              to={`/browse/${item.cat_name}/${item.cat_id}`}
                              data-text={item.cat_name}
                            >
                              {item.cat_name}
                            </Link>
                          </li>
                        }
                        {/* {

                        item.view_type !== 'radio' ? <li
                          className="list-inline-item"
                          key={i}
                          onClick={() => {
                            setIsOpenBackdrop(false);
                            setisOpenNav(false);
                          }}
                        >
                          <Link
                            to={`/browse/${item.cat_name}/${item.cat_id}`}
                            data-text={item.cat_name}
                          >
                            {item.cat_name}
                          </Link>
                        </li> : <li
                          className="list-inline-item"
                          key={i}
                          onClick={() => {
                            setIsOpenBackdrop(false);
                            setisOpenNav(false);
                          }}
                        >
                          <Link
                            to={`/radio/${item.cat_id}`}
                            data-text={item.cat_name}
                          >
                            {item.cat_name}
                          </Link>
                        </li>

                      } */}
                      </>
                    ))}
                </ul>

                {windowWidth < 992 && (
                  <>
                    <div className="d-none">
                      <Button
                        variant="outlined"
                        className="mx-2 btn-round w-100 radius-10 text-white bg-red btn-m mb-3"
                        onClick={(e) => openLoginModal("login")}
                      // onClick={(e) => handleClickOpen("login")}
                      >
                        Login
                      </Button>

                    </div>
                  </>
                )}
              </div>


              <Link to="/search">
                <Button className={`openSearch text-white mx-2`}>
                  <SearchIcon />
                </Button>
              </Link>

              <Box
                sx={{ display: "flex", paddingRight: "45px" }}
                className="loginBtnWrap loginBtnWrapRes"
              >
                <Button
                  variant="contained"
                  className="mx-2 bg-red res-hide donationBtn" onClick={() => redirectTodonation(commondataItems?.donation?.url)}
                >
                  {commondataItems?.donation?.title}
                </Button>


                {isAuth === false ? (
                  <>
                    {windowWidth > 992 ? (
                      <>
                        <Button
                          variant="contained"
                          className="mx-2 btn-gray d-none"
                          onClick={(e) => openLoginModal("login")}

                        // onClick={(e) => handleClickOpen("otpModal")}
                        >
                          Login
                        </Button>
                      </>
                    ) : (
                      <MenuIcon
                        className="openMenuTabs"
                        onClick={() => {
                          handleClick();
                          setIsOpenBackdrop(!isOpenBackdrop);
                          setisOpenNav(!isOpenNav);
                        }}
                      />
                    )}
                  </>
                ) : (
                  <div className="position-relative myAccDropdownWrapper">
                    <ClickAwayListener onClickAway={() => {
                      handleClose();

                    }}>
                      <div className="d-flex align-items-center">
                        <Button
                          className="myAccDropdown d-flex align-items-center myAccDropdownTab"
                          onClick={() => {
                            handleClick();
                            setIsOpenBackdrop(!isOpenBackdrop);
                            setisOpenNav(!isOpenNav);
                          }}
                        >
                          <span className="rounded-circle d-flex align-items-center justify-content-center">
                            <PersonIcon />
                          </span>
                        </Button>

                        <span
                          className={`rounded-circle d-flex align-items-center justify-content-center myAccDropdownWrapperIcon ${isOpenNav === true ? 'rotate' : ''}`}
                          onClick={() => {
                            handleClick();
                            setIsOpenBackdrop(!isOpenBackdrop);
                            setisOpenNav(!isOpenNav);
                          }}
                        >
                          <KeyboardArrowDownIcon />
                        </span>
                      </div>
                    </ClickAwayListener>


                    <div
                      className={`dropdown_menu acc_drop ${dropdown !== false && "show"
                        }`}
                      onClick={() => {
                        setisOpenNav(!isOpenNav)
                      }}
                    >
                      <div className="info d-flex align-items-center">
                        <span className="icon_">
                          {
                            localStorage.getItem('userType') === "email" ? <MarkEmailReadIcon className="text-white" /> : <PhoneInTalkIcon className="text-white" />
                          }

                        </span>
                        <div className="d-flex align-items-center flex-column px-3">
                          <h4 className="text-white w-100">
                            {localStorage.getItem("username")}{" "}
                            <VerifiedIcon fontSize="10px" className="" />
                          </h4>
                          <h6 className="text-white w-100">
                            {localStorage.getItem("email") !== undefined &&
                              localStorage.getItem("email") !== null &&
                              localStorage.getItem("email") !== ""
                              ? localStorage.getItem("email")
                              : `${"+" +
                              localStorage.getItem("countryCallingcode") +
                              "-"
                              }${localStorage.getItem("mobile")}`}{" "}
                          </h6>
                        </div>
                      </div>

                      {windowWidth < 992 &&
                        state.topMenu?.length > 0 &&
                        state.topMenu.map((item, i) => (
                          <>
                            {
                              item.view_type === 'radio' ? <Link
                                to={`/radio/${item.cat_id}`}
                                data-text={item.cat_name}
                                key={i}
                              >
                                <Button className="cursor" color="error">
                                  <span>{item.cat_name}</span>
                                  <KeyboardArrowRightIcon className="ml-auto font-13" />
                                </Button>
                              </Link> : item.view_type === 'tvschedule' ? <Link
                                to={`/tvschedule/${item.cat_id}`}
                                data-text={item.cat_name}
                                key={i}
                              >
                                <Button className="cursor" color="error">
                                  <span>{item.cat_name}</span>
                                  <KeyboardArrowRightIcon className="ml-auto font-13" />
                                </Button>
                              </Link> : item.view_type === 'prayer' ? <Link
                                to={`/prayer/${item.cat_id}`}
                                data-text={item.cat_name}
                                key={i}
                              >
                                <Button className="cursor" color="error">
                                  <span>{item.cat_name}</span>
                                  <KeyboardArrowRightIcon className="ml-auto font-13" />
                                </Button>
                              </Link> : <Link
                                to={`/browse/${item.view_type}/${item.cat_id}`}
                                data-text={item.cat_name}
                                key={i}
                              >
                                <Button className="cursor" color="error">
                                  <span>{item.cat_name}</span>
                                  <KeyboardArrowRightIcon className="ml-auto font-13" />
                                </Button>
                              </Link>
                            }
                            {/* {
                              item.view_type !== 'radio' ? <Link
                                to={`/browse/${item.view_type}/${item.cat_id}`}
                                data-text={item.cat_name}
                                key={i}
                              >
                                <Button className="cursor" color="error">
                                  <span>{item.cat_name}</span>
                                  <KeyboardArrowRightIcon className="ml-auto font-13" />
                                </Button>
                              </Link> : <Link
                                to={`/radio/${item.cat_id}`}
                                data-text={item.cat_name}
                                key={i}
                              >
                                <Button className="cursor" color="error">
                                  <span>{item.cat_name}</span>
                                  <KeyboardArrowRightIcon className="ml-auto font-13" />
                                </Button>
                              </Link>
                            } */}
                          </>
                        ))
                      }


                      {state.sideMenuItems &&
                        state.sideMenuItems.length > 0 &&
                        state.sideMenuItems.map((item, i) => {
                          return (
                            <Link to={"/" + item.slug} key={i}>
                              <Button className="cursor" color="error">

                                <span>{item.cat_name}</span>
                                <KeyboardArrowRightIcon className="ml-auto font-13" />
                              </Button>
                            </Link>
                          );
                        })}

                      <Logout
                        handleClose={handleClose}
                        uuid={localStorage.getItem("uuid")}
                      />
                    </div>

                  </div>
                )}






                {/* ---before login menu---- */}
                {isAuth === false &&
                  <div
                    className={`dropdown_menu acc_drop beforeLoginMenures ${dropdown !== false && "show"
                      }`}
                    onClick={() => {
                      setisOpenNav(false);
                      setDropdown(false)
                    }}
                  >
                    <div className="info">
                      <img src={localStorage.getItem('logo')} />
                    </div>

                    {windowWidth < 992 &&
                      state.topMenu?.length > 0 &&
                      state.topMenu.map((item, i) => (
                        <>
                          {

                            item.view_type === 'radio' ? <Link
                              to={`/radio/${item.cat_id}`}
                              data-text={item.cat_name}
                              key={i}
                            >
                              <Button className="cursor" color="error">
                                <span>{item.cat_name}</span>
                                <KeyboardArrowRightIcon className="ml-auto font-13" />
                              </Button>
                            </Link> : item.view_type === 'tvschedule' ? <Link
                              to={`/tvschedule/${item.cat_id}`}
                              data-text={item.cat_name}
                              key={i}
                            >
                              <Button className="cursor" color="error">
                                <span>{item.cat_name}</span>
                                <KeyboardArrowRightIcon className="ml-auto font-13" />
                              </Button>
                            </Link> : item.view_type === 'prayer' ? <Link
                              to={`/prayer/${item.cat_id}`}
                              data-text={item.cat_name}
                              key={i}
                            >
                              <Button className="cursor" color="error">
                                <span>{item.cat_name}</span>
                                <KeyboardArrowRightIcon className="ml-auto font-13" />
                              </Button>
                            </Link> : <Link
                              to={`/browse/${item.view_type}/${item.cat_id}`}
                              data-text={item.cat_name}
                              key={i}
                            >
                              <Button className="cursor" color="error">
                                <span>{item.cat_name}</span>
                                <KeyboardArrowRightIcon className="ml-auto font-13" />
                              </Button>
                            </Link>
                          }
                          {/* {item.view_type !== 'radio' ? <Link
                            to={`/browse/${item.view_type}/${item.cat_id}`}
                            data-text={item.cat_name}
                            key={i}
                          >
                            <Button className="cursor" color="error">
                              <span>{item.cat_name}</span>
                              <KeyboardArrowRightIcon className="ml-auto font-13" />
                            </Button>
                          </Link> : <Link
                            to={`/radio/${item.cat_id}`}
                            data-text={item.cat_name}
                            key={i}
                          >
                            <Button className="cursor" color="error">
                              <span>{item.cat_name}</span>
                              <KeyboardArrowRightIcon className="ml-auto font-13" />
                            </Button>
                          </Link>} */}
                        </>
                      ))
                    }

                    <div className="px-3 my-3 d-none">
                      <Button
                        variant="outlined"
                        className="btn-round w-100 radius-10 text-white bg-red btn-m mb-3"
                        onClick={(e) => openLoginModal("login")}
                      // onClick={(e) => handleClickOpen("login")}
                      >
                        Login
                      </Button>
                    </div>

                  </div>
                }
                {/*--before login menu ends  */}



              </Box>
            </div>
          </Grid>
        </Grid>
      </header>
      <LoginModal />
    </>
  );
};

const mapStateToProps = (state) => ({
  menu: state.menu.topMenuData,
  tokenData: state.getToken.tokenData,
  sideMenu: state.sideMenu.sideMenu,
  userCurrentLocation: state.getuserLocation.userCurrentLocation,
  commonData: state.common.commonData,
  isError: state.menu.isError,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTopMenu: () => dispatch(fetchTopMenuRequest()),
  fetchSideMenu: () => dispatch(fetchSideMenuRequest()),
  fetchTokenData: () => dispatch(fetchTokenRequest()),
  fetchCommonData: () => dispatch(fetchCommonDataRequest()),
  fetchIPData: () => dispatch(fetchIPRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
