// actions.js
// Action types for home data fetching request, success, and failure
export const FETCH_HOME_REQUEST = "FETCH_HOME_REQUEST";
export const FETCH_HOME_SUCCESS = "FETCH_HOME_SUCCESS";
export const FETCH_HOME_FAILURE = "FETCH_HOME_FAILURE";

// Action creator for home data fetching request
export const fetchHome = (payload) => ({
  type: FETCH_HOME_REQUEST,
  payload,
});

// Action creator for home data fetching success
export const fetchHomeSuccess = (homeData) => ({
  type: FETCH_HOME_SUCCESS,
  payload: homeData,
});

// Action creator for home data fetching failure
export const fetchHomeFailure = (error) => ({
  type: FETCH_HOME_FAILURE,
  payload: error,
});
