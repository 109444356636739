// reducers.js

import {
  FETCH_VIDEO_REQUEST, // Import action type for requesting video data
  FETCH_VIDEO_SUCCESS, // Import action type for successful video data fetch
  FETCH_VIDEO_FAILURE, // Import action type for video data fetch failure
} from "../../actions/videoActions"; // Import action types

// Initial state for the video data reducer
const initialState = {
  videoData: [], // Initial video data is an empty array
  isLoading: false, // Initial loading state is false
  isError: null, // Initial error state is null
};

// Reducer function to handle video data
const getVideoReducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VIDEO_REQUEST:
      // When requesting video data, set isLoading to true and reset isError
      return {
        ...state,
        isLoading: true,
        isError: null,
      };
    case FETCH_VIDEO_SUCCESS:
      // When video data fetch is successful, update videoData, set isLoading to false, and reset isError
      return {
        ...state,
        videoData: action.payload, // Update video data with the fetched data
        isLoading: false,
        isError: null,
      };
    case FETCH_VIDEO_FAILURE:
      // When video data fetch fails, set isLoading to false and update videoData with the error message
      return {
        ...state,
        isLoading: false,
        videoData: action.payload, // Update video data with the error message
        // isError: action.payload, // Optionally update isError with the error message
      };
    default:
      return state;
  }
};

export default getVideoReducers;
