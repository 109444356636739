// actions.js

// Action types for requesting viewall data
export const FETCH_VIEWALL_REQUEST = "FETCH_VIEWALL_REQUEST";

// Action types for successfully fetching viewall data
export const FETCH_VIEWALL_SUCCESS = "FETCH_VIEWALL_SUCCESS";

// Action types for failure in fetching viewall data
export const FETCH_VIEWALL_FAILURE = "FETCH_VIEWALL_FAILURE";

// Action creator for initiating viewall data fetch request
export const fetchViewAllRequest = (payload) => ({
  type: FETCH_VIEWALL_REQUEST,
  payload,
});

// Action creator for successful viewall data fetch
export const fetchViewAllSuccess = (viewallData) => ({
  type: FETCH_VIEWALL_SUCCESS,
  payload: viewallData,
});

// Action creator for handling failure in fetching viewall data
export const fetchViewAllFailure = (error) => ({
  type: FETCH_VIEWALL_FAILURE,
  payload: error,
});
