// actions.js

// Action types for requesting episode data
export const FETCH_DYNAMICPAGES_REQUEST = "FETCH_DYNAMICPAGES_REQUEST";

// Action types for successfully fetching episode data
export const FETCH_DYNAMICPAGES_SUCCESS = "FETCH_DYNAMICPAGES_SUCCESS";

// Action types for failure in fetching episode data
export const FETCH_DYNAMICPAGES_FAILURE = "FETCH_DYNAMICPAGES_FAILURE";

// Action creator for initiating episode data fetch request
export const fetchDynamicPagesRequest = (payload) => ({
  type: FETCH_DYNAMICPAGES_REQUEST,
  payload,
});

// Action creator for successful episode data fetch
export const fetchDynamicPagesSuccess = (dynamicpagesData) => ({
  type: FETCH_DYNAMICPAGES_SUCCESS,
  payload: dynamicpagesData,
});

// Action creator for handling failure in fetching episode data
export const fetchDynamicPagesFailure = (error) => ({
  type: FETCH_DYNAMICPAGES_FAILURE,
  payload: error,
});
