// sagas.js

import { takeLatest, put, call } from "redux-saga/effects"; // Import necessary functions from Redux Saga
import { RECOMMENDED } from "../../../utility/constants"; // Import constant for API endpoint
import axios from "axios"; // Import Axios for making HTTP requests
import {
  FETCH_RECOMMENDED_REQUEST, // Import action type for fetching recommended data
  fetchRecommendedSuccess, // Import action creator for successful fetch
  fetchRecommendedFailure, // Import action creator for fetch failure
} from "../../actions/recommendedActions"; // Import action creators for success and failure cases

function* fecthRecommended(action) {
  try {
    const recommendedFormdata = new FormData(); // Create a new FormData object for recommended data
    recommendedFormdata.append("entryid", action.payload.entryid); // Append entry ID to the FormData
    recommendedFormdata.append("content_type", action.payload.content_type); // Append content type to the FormData

    // Make POST request to the RECOMMENDED endpoint with recommendedFormdata
    const response = yield call(axios.post, RECOMMENDED, recommendedFormdata, {
      // Include token from local storage in the request headers
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    // Check if the response is successful and status is "Success"
    if (response.status === 200 && response.data.status === "Success") {
      // Dispatch action to handle successful fetch with response data
      yield put(fetchRecommendedSuccess(response.data));
    }
  } catch (error) {
    // Dispatch action to handle fetch failure with error message
    yield put(fetchRecommendedFailure(error.message));
  }
}

// Saga watcher function to listen for FETCH_RECOMMENDED_REQUEST action
export function* RecommendedSaga() {
  yield takeLatest(FETCH_RECOMMENDED_REQUEST, fecthRecommended);
}

// Export the saga watcher function as default
export default RecommendedSaga;
