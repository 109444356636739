// Importing action types for home carousel actions
import {
  FETCH_HOMECAROUSEL_REQUEST,   // Action type for requesting home carousel data
  FETCH_HOMECAROUSEL_SUCCESS,   // Action type for successful home carousel data retrieval
  FETCH_HOMECAROUSEL_FAILURE,   // Action type for home carousel data retrieval failure
} from "../../actions/homeCarouselActions";  // Importing action types from the respective action file

// Define the initial state for the reducer
const initialState = {
  homeCarouselItems: [],   // Initial value for homeCarouselItems is an empty array
  isLoading: false,        // Initial value for isLoading is false
  isError: null,           // Initial value for isError is null
};

// Define the getHomeCarousel function
const getHomeCarousel = (state = initialState, action) => {
  // Use a switch statement to handle different action types
  switch (action.type) {
    case FETCH_HOMECAROUSEL_REQUEST:
      // When the FETCH_HOMECAROUSEL_REQUEST action is dispatched
      return {
        ...state,
        isLoading: true,    // Set isLoading to true
        isError: null,      // Reset isError to null
      };
    case FETCH_HOMECAROUSEL_SUCCESS:
      // When the FETCH_HOMECAROUSEL_SUCCESS action is dispatched
      return {
        ...state,
        homeCarouselItems: action.payload,  // Update homeCarouselItems with the payload from the action
        isLoading: false,                   // Set isLoading to false
        isError: null,                      // Reset isError to null
      };
    case FETCH_HOMECAROUSEL_FAILURE:
      // When the FETCH_HOMECAROUSEL_FAILURE action is dispatched
      return {
        ...state,
        isLoading: false,          // Set isLoading to false
        isError: action.payload,   // Update isError with the payload from the action
      };
    default:
      // If none of the above action types match, return the current state
      return state;
  }
};

// Export the getHomeCarousel as the default export
export default getHomeCarousel;
