// Action Types
export const FETCH_IP_REQUEST = 'FETCH_IP_REQUEST';
export const FETCH_IP_SUCCESS = 'FETCH_IP_SUCCESS';
export const FETCH_IP_FAILURE = 'FETCH_IP_FAILURE';

// Action Creators
// Action creator for IP fetching request
export const fetchIPRequest = () => ({
  type: FETCH_IP_REQUEST,
});

// Action creator for IP fetching success
export const fetchIPSuccess = (data) => ({
  type: FETCH_IP_SUCCESS,
  payload: data,
});

// Action creator for IP fetching failure
export const fetchIPFailure = (error) => ({
  type: FETCH_IP_FAILURE,
  payload: error,
});
