// actions.js
// Action types for pre-home popup fetching request, success, and failure
export const FETCH_PREHOMEPOPUP_REQUEST = "FETCH_PREHOMEPOPUP_REQUEST";
export const FETCH_PREHOMEPOPUP_SUCCESS = "FETCH_PREHOMEPOPUP_SUCCESS";
export const FETCH_PREHOMEPOPUP_FAILURE = "FETCH_PREHOMEPOPUP_FAILURE";

// Action creator for pre-home popup fetching request
export const fecthPreHomePopupRequest = (payload) => ({
  type: FETCH_PREHOMEPOPUP_REQUEST,
  payload,
});

// Action creator for pre-home popup fetching success
export const fecthPreHomePopupSuccess = (preHomePopup) => ({
  type: FETCH_PREHOMEPOPUP_SUCCESS,
  payload: preHomePopup,
});

// Action creator for pre-home popup fetching failure
export const fecthPreHomePopupFailure = (error) => ({
  type: FETCH_PREHOMEPOPUP_FAILURE,
  payload: error,
});
