// sagas.js
import { takeLatest, put, call } from "redux-saga/effects";
import { HOME } from "../../../utility/constants";
import axios from "axios";
import {
  FETCH_HOME_REQUEST,
  fetchHomeSuccess,
  fetchHomeFailure,
} from "../../actions/fecthHomeActions";

function* fetchHomeSaga(action) {
  try {
    const homePayloadData = new FormData();
    homePayloadData.append("img_type", "w");
    homePayloadData.append("category_id", action.payload.categoryid);
    homePayloadData.append("page", action.payload.page);
    const response = yield call(axios.post, HOME, homePayloadData, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200 && response.data.status === "Success") {
      yield put(fetchHomeSuccess(response.data));
      // return response.data;
    }
  } catch (error) {
    yield put(fetchHomeFailure(error.message));
  }
}

export function* fecthHomeDataSaga() {
  yield takeLatest(FETCH_HOME_REQUEST, fetchHomeSaga);
}
export default fecthHomeDataSaga;
