// actions.js
// Action types for search data fetching request, success, and failure
export const FETCH_SEARCH_DATA_REQUEST = "FETCH_SEARCH_DATA_REQUEST";
export const FETCH_SEARCH_DATA_SUCCESS = "FETCH_SEARCH_DATA_SUCCESS";
export const FETCH_SEARCH_DATA_FAILURE = "FETCH_SEARCH_DATA_FAILURE";

// Action creator for search data fetching request
export const fetchSearchDataRequest = () => ({
  type: FETCH_SEARCH_DATA_REQUEST,
});

// Action creator for search data fetching success
export const fetchSearchDataSuccess = (isSearchItem) => ({
  type: FETCH_SEARCH_DATA_SUCCESS,
  payload: isSearchItem,
});

// Action creator for search data fetching failure
export const fetchSearchDataFailure = (error) => ({
  type: FETCH_SEARCH_DATA_FAILURE,
  payload: error,
});
