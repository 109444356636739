// DeviceManagementReducer.js

import {
  FETCH_REMOVE_DEVICEMANAGEMENT_REQUEST, // Import action type for requesting device removal
  FETCH_REMOVE_DEVICEMANAGEMENT_SUCCESS, // Import action type for successful device removal
  FETCH_REMOVE_DEVICEMANAGEMENT_FAILURE, // Import action type for device removal failure
} from "../../actions/removeDeviceManagementActions"; // Import action types

// Initial state for the device management data reducer
const initialState = {
  removeDeviceData: null, // Initial device removal data is null
  isLoading: false, // Initial loading state is false
  isError: null, // Initial error state is null
};

// Reducer function to handle device management data
const RemoveDeviceManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REMOVE_DEVICEMANAGEMENT_REQUEST:
      // When requesting device removal, set isLoading to true and reset isError
      return {
        ...state,
        isLoading: true,
        isError: null,
      };
    case FETCH_REMOVE_DEVICEMANAGEMENT_SUCCESS:
      // When device removal is successful, update removeDeviceData, set isLoading to false, and reset isError
      return {
        ...state,
        removeDeviceData: action.payload, // Update device removal data with the fetched data
        isLoading: false,
        isError: null,
      };
    case FETCH_REMOVE_DEVICEMANAGEMENT_FAILURE:
      // When device removal fails, set isLoading to false and update removeDeviceData with the error message
      return {
        ...state,
        isLoading: false,
        removeDeviceData: action.payload, // Update device removal data with the error message
      };
    default:
      return state;
  }
};

export default RemoveDeviceManagementReducer;
