// actions.js
// Action types for static page fetching request, success, and failure
export const FETCH_STATICPAGE_REQUEST = "FETCH_STATICPAGE_REQUEST";
export const FETCH_STATICPAGE_SUCCESS = "FETCH_STATICPAGE_SUCCESS";
export const FETCH_STATICPAGE_FAILURE = "FETCH_STATICPAGE_FAILURE";

// Action creator for static page fetching request
export const fetchStaticPageRequest = (payload) => ({
  type: FETCH_STATICPAGE_REQUEST,
  payload,
});

// Action creator for static page fetching success
export const fetchStaticPageSuccess = (staticpageData) => ({
  type: FETCH_STATICPAGE_SUCCESS,
  payload: staticpageData,
});

// Action creator for static page fetching failure
export const fetchStaticPageFailure = (error) => ({
  type: FETCH_STATICPAGE_FAILURE,
  payload: error,
});
