// Importing action types for fetching footer menu
import {
  FETCH_FOOTER_MENU_REQUEST,    // Action type for requesting footer menu
  FETCH_FOOTER_MENU_SUCCESS,    // Action type for successful fetching of footer menu
  FETCH_FOOTER_MENU_FAILURE,    // Action type for failure in fetching footer menu
} from '../../actions/fecthFooterMenuActions.js';  // Importing action types from the respective action file

// Define the initial state for the reducer
const initialState = {
  menuItems: [],   // Initial value for menuItems is an empty array
  // loading: false,  // Commented out loading as it's not used in this reducer
  error: null,      // Initial value for error is null
};

// Define the reducer function
const footerMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FOOTER_MENU_REQUEST:
      // When the FETCH_FOOTER_MENU_REQUEST action is dispatched
      return {
        ...state,
        loading: true,   // Set loading to true
        // error: null,    // Reset error to null
      };
    case FETCH_FOOTER_MENU_SUCCESS:
      // When the FETCH_FOOTER_MENU_SUCCESS action is dispatched
      return {
        ...state,
        menuItems: action.payload,  // Update menuItems with the payload from the action
        // loading: false,            // Set loading to false
        // error: null,               // Reset error to null
      };
    case FETCH_FOOTER_MENU_FAILURE:
      // When the FETCH_FOOTER_MENU_FAILURE action is dispatched
      return {
        ...state,
        loading: false,             // Set loading to false
        error: action.payload,      // Update error with the payload from the action
      };
    default:
      // If none of the above action types match, return the current state
      return state;
  }
};

// Export the reducer as the default export
export default footerMenuReducer;
