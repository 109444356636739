// Importing action types
import {
  FETCH_DEVICEMANAGEMENT_REQUEST,
  FETCH_DEVICEMANAGEMENT_SUCCESS,
  FETCH_DEVICEMANAGEMENT_FAILURE,
} from "../../actions/deviceManagementActions";

// Define the initial state for the reducer
const initialState = {
  deviceData: null,  // Initial value for deviceData is null
  isLoading: false,  // Initial value for isLoading is false
  isError: null,     // Initial value for isError is null
};

// Define the DeviceManagementReducer function
const DeviceManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEVICEMANAGEMENT_REQUEST:
      // When the FETCH_DEVICEMANAGEMENT_REQUEST action is dispatched
      return {
        ...state,
        isLoading: true,   // Set isLoading to true
        isError: null,     // Reset isError to null
      };
    case FETCH_DEVICEMANAGEMENT_SUCCESS:
      // When the FETCH_DEVICEMANAGEMENT_SUCCESS action is dispatched
      return {
        ...state,
        deviceData: action.payload,  // Update deviceData with the payload from the action
        isLoading: false,            // Set isLoading to false
        isError: null,               // Reset isError to null
      };
    case FETCH_DEVICEMANAGEMENT_FAILURE:
      // When the FETCH_DEVICEMANAGEMENT_FAILURE action is dispatched
      return {
        ...state,
        isLoading: false,            // Set isLoading to false
        isError: action.payload,     // Update isError with the payload from the action
      };
    default:
      // If none of the above action types match, return the current state
      return state;
  }
};

export default DeviceManagementReducer;
