import { takeLatest, call, put } from "redux-saga/effects"; // Import necessary functions from Redux Saga
import axios from "axios"; // Import Axios for making HTTP requests
import { GETUSERPROFILE } from "../../../utility/constants"; // Import constant for API endpoint
import {
  FETCH_PROFILE_DETAILS_REQUEST, // Import action type for fetching profile details
  fetchProfileDetailsSuccess, // Import action creator for successful fetch
  fetchProfileDetailsFailure, // Import action creator for fetch failure
} from "../../actions/profileActions"; // Import action creators for success and failure cases

// Saga function to fetch profile details
function* fetchProfileDetails() {
  try {
    // Make GET request to the GETUSERPROFILE endpoint
    const response = yield call(axios.get, GETUSERPROFILE, {
      // Include token from local storage in the request headers
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    // Check if the response is successful and status is "Success"
    if (response.status === 200 && response.data.status === "Success") {
      // Dispatch action to handle successful fetch with response data
      yield put(fetchProfileDetailsSuccess(response.data));
    }
  } catch (error) {
    // Dispatch action to handle fetch failure with error message
    yield put(fetchProfileDetailsFailure(error.message));
  }
}

// Watcher saga to trigger the fetch profile details request
function* getProfileSaga() {
  yield takeLatest(FETCH_PROFILE_DETAILS_REQUEST, fetchProfileDetails);
}

// Export the watcher saga function as default
export default getProfileSaga;
