import React, { useState, useRef } from "react";
import placeholder from "../../../assets/images/banner_placeholder.jpg";
import Skeleton from "@mui/material/Skeleton";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Grid from "@mui/material/Grid";
import placeHolderH from "../../../assets/images/placeholder.jpg";
import placeHolderV from "../../../assets/images/placeholder_v.jpg";

const HomeSkeleton = (props) => {
  const [showItems, setshowItems] = useState([1, 2, 3, 4, 5, 6, 7]);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [windowWidth, setwindowWidth] = useState(windowSize.current[0]);

  var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 200,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1020,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="homeLoadable">

      <div className="banner">
      {windowWidth > 992 ? (
        <>
          <img src={placeholder} className="w-100" alt="img" />

          <Skeleton
            animation="wave"
            variant="rectangular"
            width={1920}
            height={768}
            className="placeholder d-flex align-items-center justify-content-center"
          >
            <img
              //	200 × 268 px `${localStorage.getItem("logo")}/width=60/height=80/quality=60`
              src={`${localStorage.getItem("logo")}/width=200/height=268/quality=60`}
              className="img"
              alt="img"
            />
          </Skeleton>
        </>
      ) : (
        <>
          <img src={placeHolderH} className="w-100" alt="img" />

          <Skeleton
            animation="wave"
            variant="rectangular"
            width={1920}
            height={768}
            className="placeholder d-flex align-items-center justify-content-center"
          >
            <img
              src={localStorage.getItem("logo")}
              className="img"
              alt="img"
            />
          </Skeleton>
        </>
      )}
    </div>

        <section className="thumb_slider_section">
          <Grid item xs={12} className="pt-0 pb-0 thumb_slider_sectionWrapper">
            <h4
              className="title d-flex align-items-center w-100 px-2 px-2"
              style={{ opacity: "0" }}
            >
              anything
            </h4>

          </Grid>

          <div className='w-100'>
            <Grid item xs={12} className="pt-0 pb-0 thumb_slider_sectionWrapper skeleton">
          
                {
                  showItems.map((item, i) => {
                    return (
                      <div className={`item position-relative v_thumb `}>
                          <img src={placeHolderV} className="w-100 placeholderImg" />
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            width={320}
                            height={163}
                            className="d-flex align-items-center justify-content-center"
                          >
                            {/* 120 × 161 px */}
                            <img
                              src={`${localStorage.getItem("logo")}/width=120/height=161/quality=60`}
                              className="img"
                              alt="img"
                            />
                          </Skeleton>
                        </div>
                    )
                  })
                }

            
            </Grid>
          </div>


        </section>

        <section className="thumb_slider_section">
          <Grid item xs={12} className="pt-0 pb-0 thumb_slider_sectionWrapper">
            <h4
              className="title d-flex align-items-center w-100 px-2 px-2"
              style={{ opacity: "0" }}
            >
              anything
            </h4>

          </Grid>

          <div className='w-100'>
            <Grid item xs={12} className="pt-0 pb-0 thumb_slider_sectionWrapper skeleton">
          
                {
                  showItems.map((item, i) => {
                    return (
                      <div className={`item position-relative v_thumb `}>
                          <img src={placeHolderV} className="w-100 placeholderImg" />
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            width={320}
                            height={163}
                            className="d-flex align-items-center justify-content-center"
                          >
                            {/* 120 × 161 px */}
                            <img
                              src={`${localStorage.getItem("logo")}/width=120/height=161/quality=60`}
                              className="img"
                              alt="img"
                            />
                          </Skeleton>
                        </div>
                    )
                  })
                }

            
            </Grid>
          </div>


        </section>



        <section className="thumb_slider_section">
        <Grid item xs={12} className="pt-0 pb-0 thumb_slider_sectionWrapper">
          <h4
            className="title d-flex align-items-center w-100 px-2 px-2"
            style={{ opacity: "0" }}
          >
            anything
          </h4>

        </Grid>

        <div className='w-100'>
          <Grid item xs={12} className="pt-0 pb-0 thumb_slider_sectionWrapper skeleton">
        
              {
                showItems.map((item, i) => {
                  return (
                    <div className={`item position-relative v_thumb `}>
                        <img src={placeHolderV} className="w-100 placeholderImg" />
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={320}
                          height={163}
                          className="d-flex align-items-center justify-content-center"
                        >
                          {/* 120 × 161 px */}
                          <img
                            src={`${localStorage.getItem("logo")}/width=120/height=161/quality=60`}
                            className="img"
                            alt="img"
                          />
                        </Skeleton>
                      </div>
                  )
                })
              }

          
          </Grid>
        </div>


      </section>


      <section className="thumb_slider_section">
      <Grid item xs={12} className="pt-0 pb-0 thumb_slider_sectionWrapper">
        <h4
          className="title d-flex align-items-center w-100 px-2 px-2"
          style={{ opacity: "0" }}
        >
          anything
        </h4>

      </Grid>

      <div className='w-100'>
        <Grid item xs={12} className="pt-0 pb-0 thumb_slider_sectionWrapper skeleton">
      
            {
              showItems.map((item, i) => {
                return (
                  <div className={`item position-relative v_thumb `}>
                      <img src={placeHolderV} className="w-100 placeholderImg" />
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width={320}
                        height={163}
                        className="d-flex align-items-center justify-content-center"
                      >
                        {/* 120 × 161 px */}
                        <img
                          src={`${localStorage.getItem("logo")}/width=120/height=161/quality=60`}
                          className="img"
                          alt="img"
                        />
                      </Skeleton>
                    </div>
                )
              })
            }

        
        </Grid>
      </div>


    </section>


    <section className="thumb_slider_section">
    <Grid item xs={12} className="pt-0 pb-0 thumb_slider_sectionWrapper">
      <h4
        className="title d-flex align-items-center w-100 px-2 px-2"
        style={{ opacity: "0" }}
      >
        anything
      </h4>

    </Grid>

    <div className='w-100'>
      <Grid item xs={12} className="pt-0 pb-0 thumb_slider_sectionWrapper skeleton">
    
          {
            showItems.map((item, i) => {
              return (
                <div className={`item position-relative v_thumb `}>
                    <img src={placeHolderV} className="w-100 placeholderImg" />
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      width={320}
                      height={163}
                      className="d-flex align-items-center justify-content-center"
                    >
                      {/* 120 × 161 px */}
                      <img
                        src={`${localStorage.getItem("logo")}/width=120/height=161/quality=60`}
                        className="img"
                        alt="img"
                      />
                    </Skeleton>
                  </div>
              )
            })
          }

      
      </Grid>
    </div>


  </section>
      </div>
    </>
  );
};

export default HomeSkeleton;
