// actions.js

// Action types for requesting episode data
export const FETCH_PUSHNOTIFICATION_REQUEST = "FETCH_PUSHNOTIFICATION_REQUEST";

// Action types for successfully fetching episode data
export const FETCH_PUSHNOTIFICATION_SUCCESS = "FETCH_PUSHNOTIFICATION_SUCCESS";

// Action types for failure in fetching episode data
export const FETCH_PUSHNOTIFICATION_FAILURE = "FETCH_PUSHNOTIFICATION_FAILURE";

// Action creator for initiating episode data fetch request
export const fetchPushNotificationRequest = (payload) => ({
  type: FETCH_PUSHNOTIFICATION_REQUEST,
  payload,
});

// Action creator for successful episode data fetch
export const fetchPushNotificationSuccess = (pushnotification) => ({
  type: FETCH_PUSHNOTIFICATION_SUCCESS,
  payload: pushnotification,
});

// Action creator for handling failure in fetching episode data
export const fetchPushNotificationFailure = (error) => ({
  type: FETCH_PUSHNOTIFICATION_FAILURE,
  payload: error,
});
