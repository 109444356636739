// getTokenreducers.js

import {
  FETCH_COMMONDATA_REQUEST, // Import action type for requesting top menu data
  FETCH_COMMONDATA_SUCCESS, // Import action type for successful top menu data fetch
  FETCH_COMMONDATA_FAILURE, // Import action type for top menu data fetch failure
} from "../../actions/commonActions"; // Import action types

// Initial state for the top menu data reducer
const initialState = {
  commonData: [], // Initial top menu data is an empty array
  isLoading: false, // Initial loading state is false
  isError: null, // Initial error state is null
};

// Reducer function to handle top menu data
const commonDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMMONDATA_REQUEST:
      // When requesting top menu data, set isLoading to true
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_COMMONDATA_SUCCESS:
      // When top menu data fetch is successful, update commonData and set isLoading to false
      return {
        ...state,
        isLoading: false,
        commonData: action.payload, // Update top menu data with the fetched data
      };
    case FETCH_COMMONDATA_FAILURE:
      // When top menu data fetch fails, set isLoading to false, update commonData and isError with the error message
      return {
        ...state,
        isLoading: false,
        commonData: action.payload, // Update top menu data with the error message
        isError: action.payload, // Update error state with the error message
      };
    default:
      return state;
  }
};

export default commonDataReducer;
